import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { Checkbox, FormControl, FormControlLabel, InputLabel, MenuItem, Select } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import SettingsRoundedIcon from '@material-ui/icons/SettingsRounded';
import { CustomTextFieldShrink } from 'components/CustomTextFieldShrink';
import { PII_TYPE_LABELS, PiiConfig, PiiCountries, PiiCountry, PiiCurrency, PiiTypes } from 'dtos/deep-scan';
import { useCreateEditAgency } from 'queries/useAgency';
import { useUpdateDeepScan } from 'queries/useDeepScan';
import React, { useCallback, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

const useStyles = makeStyles((theme) => ({
  section: {
    '& + $section': {
      marginTop: theme.spacing(2),
    },
  },
  closeButton: {
    marginRight: theme.spacing(1),
  },
}));

interface Props {
  entityId: string;
  type: 'agency' | 'deep-scan';
  defaultValues: PiiConfig;
  applicationId?: string;
  className?: string;
  label?: string;
}

interface Form {
  country: PiiCountry;
  maskPii: boolean;
  currency: string;
  costs: PiiTypes;
}

export const ConfigurePiiCostsModal = ({ label, type, defaultValues, entityId, applicationId, className }: Props) => {
  const classes = useStyles();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const { mutate: updateDeepScan, isLoading: isLoadingDeepScan } = useUpdateDeepScan(entityId, applicationId, {
    onSuccess: () => {
      setIsModalOpen(false);
    },
  });

  const { mutate: updateAgency, isLoading: isLoadingAgency } = useCreateEditAgency(entityId, {
    onSuccess: () => {
      setIsModalOpen(false);
    },
  });

  const isLoading = isLoadingAgency || isLoadingDeepScan;

  const handleClick = useCallback(() => {
    setIsModalOpen(true);
  }, []);

  const onClose = useCallback(() => {
    setIsModalOpen(false);
  }, []);

  const { handleSubmit, control, watch, setValue } = useForm<Form>({
    defaultValues: {
      country: defaultValues.country || 'US',
      maskPii: defaultValues.maskPii,
      currency: defaultValues.currency,
      costs: defaultValues.costs,
    },
  });

  const country = watch('country');

  const onSubmit = (data: Form) => {
    if (type === 'agency') {
      updateAgency({
        piiConfig: {
          country: data.country,
          maskPii: data.maskPii,
          currency: data.currency,
          costs: data.costs,
        },
      });
    } else {
      updateDeepScan({
        piiConfig: {
          country: data.country,
          maskPii: data.maskPii,
          currency: data.currency,
          costs: data.costs,
        },
      });
    }
  };

  const FORM_ID = 'edit_deep_scan_pii_config';

  return (
    <>
      <Button className={className} onClick={handleClick} variant='text' startIcon={<SettingsRoundedIcon />}>
        {label || 'Configure PII Settings'}
      </Button>
      <Dialog fullWidth={true} open={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <DialogTitle>Configure PII Settings</DialogTitle>
        <DialogContent key='content' className='-mt-8'>
          <form onSubmit={handleSubmit(onSubmit)} id={FORM_ID}>
            <section className={classes.section}>
              {type === 'agency' ? (
                <h4 className='mt-0 mb-2'>
                  This will automatically apply to all new assessments and existing external assessments. For existing
                  risk assessments please go to assessment and change the data there.
                </h4>
              ) : (
                <h4 className='mt-0 mb-2'>Settings that require Rescan All to take effect</h4>
              )}

              <Controller
                name='country'
                control={control}
                defaultValue='US'
                render={({ field }) => (
                  <FormControl fullWidth>
                    <InputLabel id='select-pii-country'>Country *</InputLabel>
                    <Select
                      {...field}
                      labelId='select-pii-country'
                      label='Country'
                      value={field.value}
                      required
                      onChange={(el) => {
                        setValue('country', el.target.value as PiiCountry);
                        setValue('currency', PiiCurrency[el.target.value as keyof typeof PiiCurrency]);
                      }}
                    >
                      {Object.keys(PiiCountries).map((code) => (
                        <MenuItem key={code} value={code}>
                          {PiiCountries[code as PiiCountry]}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              />

              <FormControlLabel
                label='Mask PII Data'
                control={
                  <Controller
                    name='maskPii'
                    control={control}
                    render={({ field }) => <Checkbox {...field} checked={field.value} color='primary' />}
                  />
                }
              />

              <h4 className='mb-2'>Settings that take effect immediately</h4>

              <Controller
                name='currency'
                control={control}
                defaultValue=''
                render={({ field }) => <CustomTextFieldShrink {...field} label='Currency' required />}
              />

              {Object.keys(PII_TYPE_LABELS[country]).map((k) => (
                <Controller
                  name={`costs.${k}` as any}
                  key={k}
                  control={control}
                  defaultValue={defaultValues.costs[k as keyof PiiTypes]}
                  render={({ field }) => (
                    <CustomTextFieldShrink {...field} label={PII_TYPE_LABELS[country][k]} required />
                  )}
                />
              ))}
            </section>
          </form>
        </DialogContent>
        <DialogActions key='actions'>
          <div>
            <Button color='primary' variant='outlined' onClick={onClose} className={classes.closeButton}>
              Cancel
            </Button>
            <Button type='submit' color='primary' variant='contained' disabled={isLoading} form={FORM_ID}>
              {isLoading ? 'Saving...' : 'Save'}
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    </>
  );
};
