import { makeStyles } from '@material-ui/core/styles';
import { DeleteAgentModal } from 'components/modals/DeleteAgentModal';
import { ResetAgentMfaModal } from 'components/modals/ResetAgentMfaModal';
import { Column, Table } from 'components/Table';
import { AgentDTO, AgentRole } from 'dtos/agent';
import { sortBy } from 'lodash';
import { useCurrentAgencyUsers } from 'queries/useAgency';
import React, { useMemo } from 'react';
import { isAgentAnAnyAdmin } from 'telivy-selectors';

import { InviteEditAgent } from './InviteEditAgent';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(4),
  },
  title: {
    margin: 0,
    marginBottom: theme.spacing(2),
  },
  row: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: theme.spacing(2),
  },
}));

interface Props {
  agent: AgentDTO;
}

export const AgencyAgentsTable = ({ agent }: Props) => {
  const classes = useStyles();
  const { data, isLoading } = useCurrentAgencyUsers();
  const isAgencyAdmin = isAgentAnAnyAdmin(agent);

  const sortedData = useMemo(() => {
    return sortBy(data, (a) => [`${a.firstName} ${a.lastName}`, a.email]);
  }, [data]);

  const columns = useMemo((): Array<Column<AgentDTO, null>> => {
    return [
      {
        title: 'Name',
        render: (a) => `${a.firstName} ${a.lastName}`,
      },
      {
        title: 'E-mail',
        render: (a) => a.email,
      },
      {
        title: 'Role',
        render: (a) => {
          switch (a.role) {
            case AgentRole.AGENCY_ADMIN:
              return 'All Assessments';
            case AgentRole.REGULAR:
              return 'Own Assessments';
            default:
              return 'None';
          }
        },
      },
      {
        title: 'Risk Assessments',
        render: (a) => a.riskAssessmentCount,
      },
      {
        title: 'Total Assets',
        render: (a) => a.activeLightScanAssetsCount,
      },
      {
        title: 'Actions',
        render: (row) =>
          isAgencyAdmin && (
            <div className='flex gap-2'>
              <InviteEditAgent agent={row} />
              {data && (
                <>
                  <DeleteAgentModal agent={row} allAgents={data} />
                  <ResetAgentMfaModal agent={row} />
                </>
              )}
            </div>
          ),
      },
    ];
  }, [isAgencyAdmin, data]);

  return (
    <div className={classes.root}>
      <div className={classes.row}>
        <h1 className={classes.title}>Users</h1>
        {isAgencyAdmin && <InviteEditAgent />}
      </div>
      <Table<AgentDTO, null> columns={columns} rowKey={(a) => a.id} data={sortedData} loading={isLoading} />
    </div>
  );
};
