import dayjs from 'dayjs';
import { AgentDTO, AgentSettingsDTO, AgentSummaryDTO } from 'dtos/agent';
import fileDownload from 'js-file-download';
import pickBy from 'lodash/pickBy';
import qs from 'query-string';

import RequestService from './Request';

class _AgentService {
  getCurrentlyLoggedInAgent() {
    return RequestService.client.get<AgentDTO>(`/agents/current-agent-data`).then((response) => response.data);
  }

  getCustomDomainAgent(origin: string) {
    return RequestService.client
      .post<AgentDTO>(`/agents/custom-domain-agent-data`, { origin })
      .then((response) => response.data);
  }

  getSettingsForAgent() {
    return RequestService.client.get<AgentSettingsDTO>(`/agents/settings`).then((response) => response.data);
  }

  getBaseAgentById(id: string) {
    return RequestService.client.get<AgentDTO>(`/agents/base/${id}`).then((response) => response.data);
  }

  getAgentSummaryData(id: string) {
    return RequestService.client
      .get<AgentSummaryDTO>(`/agents/${id}/portfolio-summary`)
      .then((response) => response.data);
  }

  async uploadDomainsCsv(id: string, file: File) {
    const formData = new FormData();
    formData.append('file', file);

    return await RequestService.client
      .post<string>(`/agents/${id}/import-domains-csv`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response) => response.data);
  }

  async downloadExportCsv(
    id: string,
    fileName?: string,
    fromDate?: Date | null,
    uptoDate?: Date | null,
  ): Promise<void> {
    const params = qs.stringify({ ...pickBy({ fromDate, uptoDate }) });
    const csv = await RequestService.client
      .get<string>(`/agents/${id}/export-security-scans-csv?${params}`)
      .then((response) => response.data);

    await fileDownload(csv, fileName || `Export-${dayjs().format('DD-MMM-YYYY')}.csv`);
  }

  async trackAgentEvent(id: string, event: string): Promise<void> {
    return await RequestService.client
      .post<void>(`/agents/${id}/track-event`, { event })
      .then((response) => response.data);
  }

  async resendVerifyEmail(): Promise<void> {
    return await RequestService.client.post<void>(`/agents/resend-verify-email`).then((response) => response.data);
  }

  async getAllAgents() {
    return await RequestService.client.get<AgentDTO[]>(`/agents`).then((response) => response.data);
  }
}

const AgentService = new _AgentService();

export { AgentService };
