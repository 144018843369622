import { AgentDTO } from 'dtos/agent';
import React from 'react';

import { ManageApiKey } from './ManageApiKey';
import { NodewareApiKey } from './NodewareApiKey';

interface Props {
  agent: AgentDTO;
}

export const IntegrationsView: React.FC<Props> = ({ agent }) => {
  return (
    <div className='max-w-4xl'>
      <div className='flex justify-between items-center mb-4'>
        <h1 className='text-2xl m-0 font-bold text-gray-900'>Integrations</h1>
      </div>

      <div className='space-y-8'>
        <ManageApiKey agent={agent} className='bg-white rounded-lg shadow' />

        <div className='bg-white rounded-lg shadow p-6'>
          <div>
            <h3 className='text-lg mt-0 font-semibold text-gray-900 mb-3'>Nodeware</h3>
            <p className='text-gray-600 mb-6'>
              Enable Nodeware using your API Key. Then configure every assessment with nodeware customer ID to use
              Nodeware data in Telivy Reports.
            </p>

            <NodewareApiKey agent={agent} />
          </div>
        </div>
      </div>
    </div>
  );
};
