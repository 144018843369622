import { useAuth0 } from '@auth0/auth0-react';
import React, { useMemo } from 'react';
import { Helmet } from 'react-helmet';

import { useCurrentAgent } from './queries/useAgent';
import { getWebsiteName } from './telivy-selectors';

export const AppHelment = () => {
  const { isAuthenticated, isLoading } = useAuth0();
  const { data: currentUser, isLoading: loadingUser } = useCurrentAgent({ enabled: isAuthenticated });

  const name = useMemo(() => {
    if (isLoading || loadingUser) return '';

    return getWebsiteName(currentUser);
  }, [currentUser, isLoading, loadingUser]);

  return <Helmet titleTemplate={`%s – ${name}`} defaultTitle={name} />;
};
