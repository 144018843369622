import { AgentDTO } from 'dtos/agent';
import React from 'react';

import { AgenciesTable } from './AgenciesTable';

interface Props {
  agent: AgentDTO;
}

export const AccountsView: React.FC<Props> = ({ agent }) => {
  return (
    <div>
      <AgenciesTable agent={agent} />
    </div>
  );
};
