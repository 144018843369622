import { makeStyles } from '@material-ui/core/styles';
import { AxiosError } from 'axios';
import { FilePicker } from 'components/FilePicker';
import { AgentDTO } from 'dtos/agent';
import { useToasters } from 'hooks/useToasters';
import { useUpdateLogo } from 'queries/useAgency';
import React, { useCallback, useState } from 'react';
import { isAgentAnAnyAdmin } from 'telivy-selectors';
import { COLORS } from 'telivy-theme';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2),
    marginBottom: theme.spacing(4),
    justifyContent: 'space-between',
  },
  filePicker: {
    '& button': {
      padding: theme.spacing(1, 2, 1.25, 2),
      color: COLORS.BLUE_0,
      border: `1px solid ${COLORS.BLUE_0}`,
    },
  },
  logo: {
    maxHeight: 100,
    border: `1px solid ${COLORS.GREY_4}`,
    borderRadius: theme.shape.borderRadius,
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2),
  },
  flexContainer: {
    display: 'flex',
    gap: theme.spacing(1),
  },
}));

interface Props {
  agent: AgentDTO;
}

export const AgencyLogo = ({ agent }: Props) => {
  const classes = useStyles();

  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const { showToaster, toasterErrorHandler } = useToasters();

  const {
    mutate: uploadLogo,
    isLoading: isUploading,
    reset: resetUploadedLogo,
  } = useUpdateLogo({
    onSuccess: () => {
      showToaster('Successfully uploaded logo');
    },
    onError: (error: AxiosError | unknown) => {
      setSelectedFile(null);
      resetUploadedLogo();
      toasterErrorHandler(error);
    },
  });

  const handleFileChange = useCallback(
    (file: File) => {
      setSelectedFile(file);
      uploadLogo(file);
    },
    [uploadLogo],
  );

  const currentLogo = agent.agency?.logoUrl;
  const isAgencyAdmin = isAgentAnAnyAdmin(agent);
  return (
    <div className={classes.root}>
      <div className={classes.row}>
        {currentLogo && <img src={currentLogo} alt='logo' className={classes.logo} />}
        {!currentLogo && <h3>No logo</h3>}
      </div>

      {isAgencyAdmin ? (
        <div className={classes.flexContainer}>
          <FilePicker
            inputProps={{ accept: '.png,.jpg,.jpeg' }}
            onReset={() => {
              resetUploadedLogo();
              setSelectedFile(null);
            }}
            isLoading={isUploading}
            onChange={handleFileChange}
            label={currentLogo ? 'Update Logo' : 'Upload Logo'}
            selectedFile={selectedFile}
            className={classes.filePicker}
          />
        </div>
      ) : null}
    </div>
  );
};
