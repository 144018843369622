import { makeStyles } from '@material-ui/core';
import { Option, RadioSelect } from 'components/RadioSelect';
import { ApplicationWithAgentDTO, SecurityScanStatus } from 'dtos/application';
// import { DeepScanTargetDTO } from 'dtos/deep-scan';
import { CompanyDTO } from 'dtos/company';
import { useExternalScanDisabled } from 'hooks/useAccountDisabled';
import { useChartsEnabled } from 'hooks/useChartsEnabled';
import { uniq } from 'lodash';
import { useCurrentAgent } from 'queries/useAgent';
import { useApplicationSecurityScan } from 'queries/useApplications';
import { useDeepScan, useDeepScanTargets } from 'queries/useDeepScan';
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { createSecurityRoute, generateRefetchIntervalMs, ROUTES } from 'telivy-constants';
import { isAgentAnAdmin, isNodewareEnabled } from 'telivy-selectors';

import { ApplicationViewContainer } from '../ApplicationViewContainer';
import { Domains } from '../assets/targets/Domains';
// import { ConfigurePhishingSection } from './ConfigurePhishingSection';
import { ConfigureSoftwares } from './ConfigureSoftwares';
import { ApplicationSetupDeployView } from './deploy';
// import { ConnectNodeware } from './ConnectNodeware';
// import { InstallAgentsSection } from './InstallAgentsSection';
// import { InstallSaasAlertsSection } from './InstallSaasAlerts';
import { ApplicationSetupOptionalView } from './optional';
// import { ScanBrowserPasswordsSection } from './ScanBrowserPasswordsSection';
// import { UninstallAgentsSection } from './UninstallAgentsSection';
// import { UploadEmployeeListSection } from './UploadEmployeeListSection';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    width: '100%',
    gap: theme.spacing(2),
  },
  table: {
    width: '100%',
  },

  selectsContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(2),
  },
  select: {},
}));

export type SourceRoute = {
  security: {
    OVERVIEW: string;
    TARGETS: string;
    EXTERNAL_SCAN: string;
    FINDINGS: string;
    FINDING: string;
    BROWSER_PASSWORDS: string;
    TECHSTACK: string;
    PASSWORDS: string;
    VULNERABILITIES: string;
    COMPANY_INFO: string;
    DOWNLOAD: string;
  };
  targets: {
    PARENT: string;
    ROOT: string;
    DOMAINS: string;
    DEVICES: string;
  };
  setup: {
    PARENT: string;
    ROOT: string;
    DOMAINS: string;
    deploy: {
      PARENT: string;
      ROOT: string;
      INSTRUCTIONS: string;
      DEVICES: string;
    };
    CLOUD: string;
    optional: {
      PARENT: string;
      ROOT: string;
      EMAIL_LIST: string;
      NODEWARE: string;
      REMOVE: string;
    };
  };
};

interface Props {
  application: ApplicationWithAgentDTO;
  sourceRoute: SourceRoute;
  company?: CompanyDTO;
  setSecurityOverallScore: (score: number | null) => void;
}

export const ApplicationConfigurationView = ({ application, sourceRoute, company, setSecurityOverallScore }: Props) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const { data: agent } = useCurrentAgent();

  const deepScanId = application.deepScanId || '';
  const { data: applicationData } = useApplicationSecurityScan(application.id, 'latest');
  const { data: deepScan } = useDeepScan(deepScanId, {
    enabled: Boolean(deepScanId),
    retry: 1,
  });

  useEffect(() => {
    if (applicationData) {
      setSecurityOverallScore(((applicationData.securityScore || 0) * 100) / 5);
    }
  }, [applicationData, setSecurityOverallScore]);

  const externalScanDisabled = useExternalScanDisabled();
  const enableCharts = useChartsEnabled(deepScan, application);

  const securityScanId = 'latest';
  const { data: securityScan } = useApplicationSecurityScan(application.id, securityScanId, {
    refetchInterval: (data) =>
      data && data?.status !== SecurityScanStatus.COMPLETED ? generateRefetchIntervalMs() : false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
  });

  const { data: deepScanTargets } = useDeepScanTargets(deepScanId, {
    enabled: Boolean(deepScanId),
  });

  const viewOptions: Option<ValueOf<Omit<SourceRoute['setup'], 'optional' | 'deploy'>>>[] = React.useMemo(() => {
    const options = [];
    let index = 0;

    let allDomains: string[] = [];

    if (securityScan) {
      allDomains = (securityScan.domain !== 'nodomain.local' ? [securityScan.domain] : [])
        .concat(securityScan.ipAddresses || [], securityScan.otherDomains || [])
        .filter((d) => (securityScan.excludedTargets || []).indexOf(d) <= -1);
    }

    index += 1;
    options.push({
      label: `${index}. Manage Domains (${uniq(allDomains).length})`,
      value: createSecurityRoute(sourceRoute.setup.DOMAINS, application.id),
    });

    options.push({
      label: `2. Deploy Agent (${deepScanTargets?.length || 0})`,
      value: createSecurityRoute(sourceRoute.setup.deploy.ROOT, application.id),
    });

    options.push({
      label: `3. Connect Cloud (${(deepScan?.m365Connected ? 1 : 0) + (deepScan?.gwsConnected ? 1 : 0)})`,
      value: createSecurityRoute(sourceRoute.setup.CLOUD, application.id),
    });

    options.push({
      label: `4. Optional Steps`,
      value: createSecurityRoute(sourceRoute.setup.optional.ROOT, application.id),
    });

    return options;
  }, [
    securityScan,
    sourceRoute.setup.DOMAINS,
    sourceRoute.setup.deploy.ROOT,
    sourceRoute.setup.CLOUD,
    sourceRoute.setup.optional.ROOT,
    application.id,
    deepScanTargets?.length,
    deepScan?.m365Connected,
    deepScan?.gwsConnected,
  ]);

  return (
    <ApplicationViewContainer>
      <Helmet>
        <title>Setup - {application?.applicationResponse?.organization_name}</title>
      </Helmet>

      <div className={classes.selectsContainer}>
        <RadioSelect<ValueOf<Omit<SourceRoute['setup'], 'optional' | 'deploy'>>>
          responsive
          options={viewOptions}
          className={classes.select}
          selectedValue={location.pathname}
          onOptionSelect={(opt) => navigate(opt.value)}
          selectedFn={(optionValue, selectedValue) => (selectedValue ? selectedValue.includes(optionValue) : false)}
        />
      </div>

      <Routes>
        <Route
          path={sourceRoute.setup.DOMAINS}
          element={
            securityScan && (
              <Domains
                securityScan={securityScan}
                securityScanId={securityScan.id}
                applicationId={application.id}
                application={application}
                sourceRoute={ROUTES.agent.application}
                externalScanDisabled={externalScanDisabled}
              />
            )
          }
        />

        <Route
          path={sourceRoute.setup.deploy.PARENT}
          element={
            <ApplicationSetupDeployView
              application={application}
              deepScan={deepScan}
              sourceRoute={sourceRoute.setup}
              company={company}
              deepScanTargets={deepScanTargets}
              isAdmin={isAgentAnAdmin(agent)}
              enableCharts={enableCharts}
            />
          }
        />

        <Route
          path={sourceRoute.setup.CLOUD}
          element={deepScan && <ConfigureSoftwares application={application} deepScan={deepScan} />}
        />

        <Route
          path={sourceRoute.setup.optional.PARENT}
          element={
            <ApplicationSetupOptionalView
              application={application}
              deepScan={deepScan}
              sourceRoute={sourceRoute.setup}
              isNodewareEnabled={isNodewareEnabled(agent)}
            />
          }
        />

        <Route index element={<Navigate replace to={sourceRoute.setup.deploy.ROOT} />} />
      </Routes>
    </ApplicationViewContainer>
  );
};
