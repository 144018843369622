import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { CompanyLogo } from 'components/CompanyLogo';
import { CreateApplicationButton } from 'components/CreateApplicationButton';
import { useCurrentAgent } from 'queries/useAgent';
import React from 'react';
import { Link, RouteProps } from 'react-router-dom';
// import { withRouter } from 'react-router-dom';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { ROUTES } from 'telivy-constants';
// import { isAgentAGlobalAdmin } from 'telivy-selectors';
import { COLORS } from 'telivy-theme';

import { Menu } from './Menu';
import { TrialStatus } from './TrialStatus';
import { UserStatus } from './UserStatus';

function withRouter(Component: any) {
  function ComponentWithRouterProp(props: RouteProps) {
    const location = useLocation();
    const navigate = useNavigate();
    const params = useParams();

    return <Component {...props} router={{ location, navigate, params }} />;
  }

  return ComponentWithRouterProp;
}

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: COLORS.GREY_6,
    padding: `0 ${theme.spacing(4)}px`,
    gap: theme.spacing(1),
  },
  logoWrapper: {
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      justifyContent: 'center',
    },
  },
  logo: {
    height: theme.spacing(5),
  },
  menu: {},
  trial: {},
  createButton: {
    marginLeft: 'auto',
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(0.5),
  },
}));

function Navbar() {
  const classes = useStyles();
  const { data: agentData } = useCurrentAgent();

  return (
    <Box component='nav' aria-label='navbar' className={classes.root}>
      <Link to={ROUTES.agent.DASHBOARD} className={classes.logoWrapper}>
        {agentData && <CompanyLogo className={classes.logo} agent={agentData} />}
      </Link>
      <Menu className={classes.menu} agent={agentData} />
      <TrialStatus className={classes.trial} agent={agentData} />

      <div className={classes.createButton}>
        {agentData && <CreateApplicationButton agent={agentData} />}
        <UserStatus />
      </div>
    </Box>
  );
}

export default withRouter(Navbar);
