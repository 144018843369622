import { RedirectLoginOptions, useAuth0 } from '@auth0/auth0-react';
import { LoadingContainer } from 'components/LoadingContainer';
import React, { useEffect } from 'react';
import { RouteProps } from 'react-router';
import { Outlet } from 'react-router-dom';

type PrivateRouteProps = RouteProps & {
  redirectToSignup?: boolean;
};

const ProtectedRoute = ({ redirectToSignup }: PrivateRouteProps) => {
  const { isAuthenticated, isLoading, loginWithRedirect } = useAuth0();
  const returnTo = `${window.location.pathname}${window.location.search}`;

  useEffect(() => {
    if (isLoading || isAuthenticated) {
      return;
    }

    const opts: RedirectLoginOptions = {
      screen_hint: redirectToSignup ? 'signup' : 'login',
      appState: {
        returnTo,
      },
      fragment: window.location.search + (redirectToSignup ? `&signup=1` : ''),
    };

    (async (): Promise<void> => {
      await loginWithRedirect(opts);
    })();
  }, [isLoading, isAuthenticated, loginWithRedirect, returnTo, redirectToSignup]);

  return isAuthenticated ? <Outlet /> : <LoadingContainer fullScreen />;
};

export default ProtectedRoute;
