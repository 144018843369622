import { makeStyles } from '@material-ui/core/styles';
import cx from 'classnames';
import { AgentDTO } from 'dtos/agent';
import React from 'react';
import { Link } from 'react-router-dom';
import { API_URL, ROUTES, SUPPORT_URL } from 'telivy-constants';
import { canAgentSeeAccount, isSecurityAgency } from 'telivy-selectors';
import { COLORS, TYPOGRAPHY } from 'telivy-theme';

// const unusedLinks = [
//   {
//     url: ROUTES.agent.SUMMARY,
//     label: isSecurityAgency(agent) ? 'Summary' : 'Security Summary',
//     hide: !isSecurityAgency(agent),
//   },
//   {
//     url: `${insertIdToRoute(ROUTES.agent.application.ROOT, DEMO_ID)}`,
//     label: 'Demo',
//     newTab: false,
//   },
// ];

const useStyles = makeStyles((theme) => ({
  root: {},
  list: {
    ...TYPOGRAPHY.SMALL_MEDIUM,
    display: 'flex',
  },
  item: {
    display: 'flex',
    alignItems: 'center',
    textDecoration: 'none',
    padding: `${theme.spacing(2)}px ${theme.spacing(1.5)}px`,
    color: COLORS.GREY_1,
    borderBottom: `2px solid transparent`,
    transition: 'all 0.3s',
  },
  activeItem: {
    borderColor: COLORS.BLUE_1,
  },

  icon: {
    marginRight: theme.spacing(),
  },
}));

interface Props {
  agent?: AgentDTO;
  className?: string;
}

export const Menu = ({ agent, className }: Props) => {
  const classes = useStyles();

  const generateItems = (agent?: AgentDTO) => {
    const isGlobalAdmin = agent?.role === 'agency_admin' && agent?.agency?.isMasterAgency;
    const isAgencyCrmEnabled = agent?.agency?.enableCrm;
    const isAgencyAlertsEnabled = agent?.agency?.enableAlerts;
    // const isAgencyAdmin = !isGlobalAdmin && (agent?.role === 'agency_admin' || agent?.role === 'telivy_admin');

    const items = [
      {
        url: `${ROUTES.agent.ROOT}/${ROUTES.agent.CRM}`,
        label: 'Companies',
        newTab: false,
        hideLink: !isAgencyCrmEnabled,
      },
      {
        url: `${ROUTES.agent.ROOT}/${ROUTES.agent.DASHBOARD}`,
        label: isSecurityAgency(agent) ? 'Assessments' : 'Applications',
        newTab: false,
      },
      {
        url: `${ROUTES.agent.ROOT}/${ROUTES.agent.alerts.ROOT}`,
        label: 'Alerts',
        newTab: false,
        hideLink: !isAgencyAlertsEnabled,
      },
      {
        url: ROUTES.agent.account.ROOT,
        label: 'Account',
        newTab: false,
        hideLink: !canAgentSeeAccount(agent),
      },
      {
        url: SUPPORT_URL,
        label: 'Support',
        newTab: true,
      },
      {
        url: `${API_URL.slice(0, -7)}/api-docs/security`,
        label: 'API',
        newTab: true,
        hideLink: !(agent && !agent.masterLogo),
      },
      {
        url: ROUTES.agent.admin.ROOT,
        label: 'Global Admin',
        newTab: false,
        hideLink: !isGlobalAdmin,
      },
    ];

    return items.filter((link) => !link?.hideLink);
  };

  return (
    <div className={cx(classes.root, className)}>
      <div className={classes.list}>
        {generateItems(agent).map((el, idx) =>
          !el.newTab ? (
            <Link
              to={el.url}
              key={idx}
              className={cx(classes.item, location.pathname.startsWith(el.url.split('?')[0]) && classes.activeItem)}
            >
              {el.label}
            </Link>
          ) : (
            <a
              href={el.url}
              key={idx}
              target='_blank'
              rel='noreferrer'
              className={cx(classes.item, location.pathname.startsWith(el.url) && classes.activeItem)}
            >
              {el.label}
            </a>
          ),
        )}
      </div>
    </div>
  );
};
