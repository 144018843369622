import { AgentDTO, AgentRole } from 'dtos/agent';
import React from 'react';

import { EditAgencyNameButton } from './EditAgencyNameButton';

interface Props {
  agent: AgentDTO;
}

export const AgencyData = ({ agent }: Props) => {
  const isAgencyAdmin = [AgentRole.AGENCY_ADMIN, AgentRole.TELIVY_ADMIN].includes(agent.role);
  const isMasterAgency = agent.agency?.isMasterAgency;

  return (
    <div className='flex justify-between items-start'>
      <div className='flex flex-col mb-4'>
        <h2 className='m-0'>{agent.agency?.name || 'Not named yet'}</h2>
        {isMasterAgency && (
          <p className='m-0 mt-1 text-xs'>This name will be used as a white label and shown accross the platform</p>
        )}
      </div>

      {isAgencyAdmin ? <EditAgencyNameButton agent={agent} /> : null}
    </div>
  );
};
