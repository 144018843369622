import { Button } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import AddIcon from '@material-ui/icons/Add';
import { ApplicationDocumentType, ApplicationWithAgentDTO } from 'dtos/application';
import { ToasterVariant, useToasters } from 'hooks/useToasters';
import {
  useCreateApplicationDocument,
  useGenerateApplicationDocument,
  useUploadApplicationDocument,
} from 'queries/useApplications';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { DOCUMENT_TYPE_HUMANIZED_NAME } from 'telivy-maps';

interface Props {
  application: ApplicationWithAgentDTO;
  reports?: boolean;
  isAdmin?: boolean;
}

export const UploadDocument = ({ application, isAdmin }: Props) => {
  const { showToaster, toasterErrorHandler } = useToasters();
  const fileInput = useRef<null | HTMLInputElement>(null);
  const validTypes = useMemo(() => {
    const isExternalScan = !application.isDeepScan && !application.isLightScan;
    if (isExternalScan) {
      return [
        ApplicationDocumentType.TELIVY_EXTERNAL_SCAN_REPORT,
        ApplicationDocumentType.TELIVY_DETAILED_EXTERNAL_SCAN_REPORT,
        ApplicationDocumentType.TELIVY_QUESTIONNAIRE_REPORT,
      ];
    }

    return [
      ApplicationDocumentType.TELIVY_COMPLETE_REPORT_PDF,
      ApplicationDocumentType.TELIVY_COMPLETE_REPORT_DOCX,
      ApplicationDocumentType.AUGMENTT_OVERVIEW_REPORT,
      ApplicationDocumentType.AUGUMENT_DETAILED_REPORT,
      ApplicationDocumentType.NODEWARE_TOP_VULNERABILITIES_REPORT,
      ApplicationDocumentType.TELIVY_VULNERABILITIES_REPORT,
      ApplicationDocumentType.NODEWARE_EXECUTIVE_REPORT,
      ApplicationDocumentType.TELIVY_EXTERNAL_SCAN_REPORT,
      ApplicationDocumentType.TELIVY_DETAILED_EXTERNAL_SCAN_REPORT,
      ApplicationDocumentType.TELIVY_PASSWORDS_REPORT,
      ApplicationDocumentType.TELIVY_PASSWORDS_REUSE_REPORT,
      ApplicationDocumentType.TELIVY_DARK_WEB_REPORT,
      ApplicationDocumentType.TELIVY_NETWORK_INVENTORY_REPORT,
      ApplicationDocumentType.ACTIFILE_SENSITIVE_DOCUMENTS,
      ApplicationDocumentType.ACTIFILE_DETAILED_RISK,
      ApplicationDocumentType.M365_SECURITY_REPORT,
      ApplicationDocumentType.M365_SECURITY_DATA_REPORT,
      ApplicationDocumentType.GWS_SECURITY_REPORT,
      ApplicationDocumentType.GWS_SECURITY_DATA_REPORT,
      ApplicationDocumentType.TELIVY_QUESTIONNAIRE_REPORT,
      ApplicationDocumentType.NODEWARE_NETWORK_REPORT,
    ];
  }, [application.isDeepScan, application.isLightScan]);
  const [documentType, setDocumentType] = useState<ApplicationDocumentType>(validTypes[0]);
  const [file, setFile] = useState<File | null>(null);
  const { mutate: createDocument, isLoading: isCreating } = useCreateApplicationDocument(application.id);
  const { mutateAsync: uploadFile, isLoading: isUploading } = useUploadApplicationDocument(application.id);
  const { mutateAsync: generateFile, isLoading: isGenerating } = useGenerateApplicationDocument(application.id);

  const handleInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const list = e.target.files;

      if (list) {
        setFile(list[0]);
      }
    },
    [setFile],
  );

  const handleUploadClick = useCallback(async () => {
    if (file) {
      try {
        const isImage = ['image/jpeg', 'image/png'].includes(file['type']);
        if (documentType === ApplicationDocumentType.SECURITY_REPORT && !isImage) {
          return showToaster({ message: 'Security Report must be a JPEG or PNG', variant: ToasterVariant.ERROR });
        }

        const data = await uploadFile({
          type: documentType,
          file: file,
        });

        await createDocument({
          documentPath: data.path,
          fileName: file.name,
          documentType: documentType,
        });

        showToaster(`Successfully uploaded a ${DOCUMENT_TYPE_HUMANIZED_NAME[documentType]}`);
        setFile(null);
        setDocumentType(validTypes[0]);

        if (fileInput.current) fileInput.current.value = '';
      } catch (e) {
        toasterErrorHandler(e);
      }
    }
  }, [file, documentType, uploadFile, createDocument, showToaster, validTypes, toasterErrorHandler]);

  const handleGenerateClick = useCallback(async () => {
    try {
      await generateFile({ documentType: documentType });
      showToaster(`Successfully generated ${DOCUMENT_TYPE_HUMANIZED_NAME[documentType]}`);
      setFile(null);
      setDocumentType(validTypes[0]);

      if (fileInput.current) fileInput.current.value = '';
    } catch (e) {
      toasterErrorHandler(e);
    }
  }, [generateFile, documentType, showToaster, validTypes, toasterErrorHandler]);

  return (
    <div className='py-3 px-2 flex gap-2 justify-between border border-solid border-gray-100 rounded-2xl'>
      <div className='flex gap-2 items-center'>
        <Select
          className='border-solid border overflow-hidden border-gray-200 rounded-xl px-3 py-0.5 !text-sm !font-semibold'
          value={documentType}
          onChange={(el) => {
            setDocumentType(el.target.value as ApplicationDocumentType);
          }}
        >
          {validTypes.map((type) => (
            <MenuItem key={type} value={type}>
              {DOCUMENT_TYPE_HUMANIZED_NAME[type]}
            </MenuItem>
          ))}
        </Select>

        <Button disabled={isGenerating} onClick={handleGenerateClick}>
          {isGenerating ? 'Generating...' : 'Generate'}
        </Button>
      </div>

      {isAdmin && (
        <div className='flex gap-2 items-center text-xs rounded-xl pl-4'>
          <div className='font-semibold'>Admin only:</div>
          <input style={{ display: 'none' }} type='file' ref={fileInput} onChange={handleInputChange} />

          <Button
            startIcon={<AddIcon />}
            onClick={() => {
              if (fileInput.current) fileInput.current.click();
            }}
          >
            {file ? `Selected ${file.name}` : 'Select Document'}
          </Button>

          <Button disabled={!file} onClick={handleUploadClick}>
            {isCreating || isUploading ? 'Uploading...' : 'Upload'}
          </Button>
        </div>
      )}
    </div>
  );
};
