import dayjs from 'dayjs';
import { GeneratedUploadUrlResponseDTO } from 'dtos/_common';
import {
  ApplicationBreachDataDTO,
  ApplicationByIdResponse,
  ApplicationDocumentDTO,
  ApplicationDocumentsGroupDTO,
  ApplicationDocumentType,
  ApplicationDTO,
  ApplicationQuotesCount,
  ApplicationRawDnbDTO,
  ApplicationsAdminResponse,
  ApplicationSecurityStats,
  ApplicationsQuery,
  ApplicationsResponse,
  ApplicationStatus,
  ApplicationVersionDTO,
  ApplicationWithAgentDTO,
  AssignDocumentDTO,
  CreateApplicationDocumentDTO,
  CreateApplicationDTO,
  CreateTargetDTO,
  GenerateApplicationDocumentDTO,
  MonitoringSnapshotDTO,
  ScanDnsResponseDTO,
  ScanEmailResponseDTO,
  ScanIpReputationResponseDTO,
  ScanNetworkResponseDTO,
  SecurityScanDTO,
  SecurityScanHistoryDTO,
  TargetDTO,
  UpdateApplicationSettingDTO,
} from 'dtos/application';
import { CreateManualDeclinationDTO, DeclinationDTO } from 'dtos/declinations';
import { PolicyDTO } from 'dtos/policy';
import { ProposalDTO, ProposalWithApplicationDTO } from 'dtos/proposal';
import { CreateManualQuoteDTO, QuoteDocumentDTO, QuoteDocumentType, QuoteDTO } from 'dtos/quotes';
import fileDownload from 'js-file-download';
import pickBy from 'lodash/pickBy';
import qs from 'query-string';
import { QuoteService } from 'services/Quote';

import RequestService from './Request';

class _ApplicationService {
  getNaicsCodes() {
    return RequestService.client.get<string[]>('/applications/naics-codes').then((response) => response.data);
  }

  getApplicationSecurityStats(id: string) {
    return RequestService.client
      .get<ApplicationSecurityStats>(`/applications/${id}/security-dashboard`)
      .then((response) => response.data);
  }

  getApplicationIndication(id: string) {
    return RequestService.client.get<number>(`/applications/${id}/indication`).then((response) => response.data);
  }

  getApplicationScanEmail(id: string) {
    return RequestService.client
      .get<ScanEmailResponseDTO>(`/applications/${id}/scan-email`)
      .then((response) => response.data);
  }

  getApplicationScanDns(id: string) {
    return RequestService.client
      .get<ScanDnsResponseDTO>(`/applications/${id}/scan-dns`)
      .then((response) => response.data);
  }

  getApplicationScanIpReputation(id: string) {
    return RequestService.client
      .get<ScanIpReputationResponseDTO>(`/applications/${id}/scan-ip-reputation`)
      .then((response) => response.data);
  }

  getApplicationScanNetwork(id: string) {
    return RequestService.client
      .get<ScanNetworkResponseDTO>(`/applications/${id}/scan-network`)
      .then((response) => response.data);
  }

  getApplicationSecurityScan(id: string, securityScanId: string) {
    return RequestService.client
      .get<SecurityScanDTO>(`/applications/${id}/security-scan/${securityScanId == 'latest' ? '' : securityScanId}`)
      .then((response) => response.data);
  }

  getApplicationSecurityScanForceRescan(id: string, securityScanId: string, emailNotif?: boolean) {
    return RequestService.client
      .get<SecurityScanDTO>(
        `/applications/${id}/security-scan/${securityScanId == 'latest' ? '' : securityScanId}?force=1${
          emailNotif ? '&notifyViaEmail=1' : ''
        }`,
      )
      .then((response) => response.data);
  }

  getApplicationScanHistory(id: string) {
    return RequestService.client
      .get<SecurityScanHistoryDTO>(`/applications/${id}/scan-history`)
      .then((response) => response.data);
  }

  getApplicationMonitoringSnapshots(id: string, limit: number) {
    const params = qs.stringify({ limit });
    return RequestService.client
      .get<MonitoringSnapshotDTO[]>(`/applications/${id}/monitoring-snapshots?${params}`)
      .then((response) => response.data);
  }

  getApplicationById(id: string) {
    return RequestService.client
      .get<ApplicationByIdResponse>(`/applications/client/${id}`)
      .then((response) => response.data.application);
  }

  getLatestApplication() {
    return RequestService.client
      .get<ApplicationByIdResponse>(`/applications/latest`)
      .then((response) => response.data.application);
  }

  getLatestSecurityApplication() {
    return RequestService.client
      .get<ApplicationByIdResponse>(`/applications/latest-security`)
      .then((response) => response.data.application);
  }

  async submitApplication(id: string, isValid: boolean): Promise<ApplicationWithAgentDTO> {
    return RequestService.client
      .post<ApplicationByIdResponse>(`/applications/client/${id}/submit`, { isValid })
      .then((response) => response.data.application);
  }

  clientEditApplication(id: string, data: unknown): Promise<ApplicationWithAgentDTO> {
    return RequestService.client
      .put<ApplicationByIdResponse>(`/applications/client/${id}`, data)
      .then((response) => response.data.application);
  }

  clientApplicationActivePolicy(id: string): Promise<PolicyDTO> {
    return RequestService.client.get<PolicyDTO>(`/applications/client/${id}/policy`).then((response) => response.data);
  }

  getApplicationQuotes(applicationId: string) {
    return RequestService.client
      .get<QuoteDTO[]>(`/applications/${applicationId}/quotes`)
      .then((response) => response.data);
  }

  getApplicationDeclinations(applicationId: string) {
    return RequestService.client
      .get<DeclinationDTO[]>(`/applications/${applicationId}/declinations`)
      .then((response) => response.data);
  }

  getApplicationPolicies(applicationId: string) {
    return RequestService.client
      .get<PolicyDTO[]>(`/applications/${applicationId}/policies`)
      .then((response) => response.data);
  }

  getAllApplications(query: ApplicationsQuery) {
    const params = qs.stringify({ ...pickBy(query) }); // Filter out undefined values
    return RequestService.client.get<ApplicationsResponse>(`/applications?${params}`).then((response) => response.data);
  }

  getAllAdminApplications(query: ApplicationsQuery) {
    const params = qs.stringify({ ...pickBy(query) }); // Filter out undefined values
    return RequestService.client
      .get<ApplicationsAdminResponse>(`/applications/admin?${params}`)
      .then((response) => response.data);
  }

  createApplication(data: CreateApplicationDTO): Promise<ApplicationDTO> {
    return RequestService.client
      .post<ApplicationByIdResponse>('/applications', data)
      .then((response) => response.data.application);
  }

  editApplication(id: string, data: unknown): Promise<ApplicationDTO> {
    return RequestService.client
      .put<ApplicationByIdResponse>(`/applications/${id}`, data)
      .then((response) => response.data.application);
  }

  forceChangeApplicationStatus(id: string, status: ApplicationStatus): Promise<ApplicationDTO> {
    return RequestService.client
      .put<ApplicationWithAgentDTO>(`/applications/${id}/status`, { applicationStatus: status })
      .then((response) => response.data);
  }

  downloadApplication(id: string, organizationName: string) {
    return RequestService.client.get<Blob>(`/applications/${id}/pdf-download`, { responseType: 'blob' }).then((res) => {
      // TODO get fileName from backend response
      const fileName: string = `${organizationName}_application_${dayjs().format('MM-DD-YYYY MM-HH')}.pdf`;
      fileDownload(res.data, fileName);
    });
  }

  duplicateApplication(id: string): Promise<ApplicationDTO> {
    return RequestService.client
      .post<ApplicationByIdResponse>(`/applications/${id}/duplicate`, {})
      .then((response) => response.data.application);
  }

  async markApplicationSubmitted(id: string): Promise<ApplicationDTO> {
    return RequestService.client
      .post<ApplicationByIdResponse>(`/applications/${id}/submitted`)
      .then((response) => response.data.application);
  }

  async markApplicationNotSubmitted(id: string): Promise<ApplicationDTO> {
    return RequestService.client
      .post<ApplicationByIdResponse>(`/applications/${id}/not-submitted`)
      .then((response) => response.data.application);
  }

  async markApplicationArchivedBulk(ids: string[]): Promise<void> {
    return RequestService.client
      .post<void>(`/applications/archived-bulk`, {
        applicationIds: ids,
      })
      .then((response) => response.data);
  }

  async changeApplicationUserBulk(ids: string[], agentId: string): Promise<void> {
    return RequestService.client
      .post<void>(`/applications/reassign-bulk`, {
        applicationIds: ids,
        agentId,
      })
      .then((response) => response.data);
  }

  async markApplicationArchived(id: string): Promise<ApplicationDTO> {
    return RequestService.client
      .post<ApplicationByIdResponse>(`/applications/${id}/archived`)
      .then((response) => response.data.application);
  }

  async markApplicationNotArchived(id: string): Promise<ApplicationDTO> {
    return RequestService.client
      .post<ApplicationByIdResponse>(`/applications/${id}/not-archived`)
      .then((response) => response.data.application);
  }

  async markApplicationRequestForQuote(id: string): Promise<ApplicationDTO> {
    return RequestService.client
      .post<ApplicationByIdResponse>(`/applications/${id}/request-for-quote`)
      .then((response) => response.data.application);
  }

  async markApplicationRequestForReport(id: string): Promise<ApplicationDTO> {
    return RequestService.client
      .post<ApplicationByIdResponse>(`/applications/${id}/request-for-report`)
      .then((response) => response.data.application);
  }

  async convertToRiskAssessment(id: string): Promise<ApplicationDTO> {
    return RequestService.client
      .post<ApplicationByIdResponse>(`/applications/${id}/convert-to-risk-assessment`)
      .then((response) => response.data.application);
  }

  async markApplicationRequestForConfiguration(id: string): Promise<ApplicationDTO> {
    return RequestService.client
      .post<ApplicationByIdResponse>(`/applications/${id}/request-for-configuration`)
      .then((response) => response.data.application);
  }

  async markApplicationRescanAll(id: string, reason?: string): Promise<ApplicationDTO> {
    return RequestService.client
      .post<ApplicationByIdResponse>(`/applications/${id}/rescan-all`, {
        reason,
      })
      .then((response) => response.data.application);
  }

  async markApplicationUninstallAll(id: string, reason?: string): Promise<ApplicationDTO> {
    return RequestService.client
      .post<ApplicationByIdResponse>(`/applications/${id}/uninstall-all`, {
        reason,
      })
      .then((response) => response.data.application);
  }

  generateApplicationPDF(id: string) {
    return RequestService.client.get<void>(`/applications/${id}/pdf-generate`);
  }

  getApplicationProposals(id: string) {
    return RequestService.client.get<ProposalDTO[]>(`/applications/${id}/proposals`).then((response) => response.data);
  }

  createApplicationProposal(id: string, quoteIds: string[]): Promise<ProposalDTO> {
    return RequestService.client
      .post<ProposalDTO>(`/applications/${id}/proposals`, { quoteIds })
      .then((response) => response.data);
  }

  getApplicationDocuments(applicationId: string): Promise<ApplicationDocumentDTO[]> {
    return RequestService.client
      .get<ApplicationDocumentDTO[]>(`/applications/${applicationId}/documents`)
      .then((response) => response.data);
  }

  getApplicationDocumentsGroups(applicationId: string): Promise<ApplicationDocumentsGroupDTO[]> {
    return RequestService.client
      .get<ApplicationDocumentsGroupDTO[]>(`/applications/${applicationId}/documents-groups`)
      .then((response) => response.data);
  }

  deleteApplicationDocumentsGroup(applicationId: string, groupId: string): Promise<void> {
    return RequestService.client
      .delete<void>(`/applications/${applicationId}/documents-groups/${groupId}`)
      .then((response) => response.data);
  }

  deleteApplication(applicationId: string): Promise<void> {
    return RequestService.client.delete<void>(`/applications/${applicationId}`).then((response) => response.data);
  }

  assignDocumentToGroup(
    applicationId: string,
    documentId: string,
    data: AssignDocumentDTO,
  ): Promise<ApplicationDocumentDTO> {
    return RequestService.client
      .post<ApplicationDocumentDTO>(`/applications/${applicationId}/documents/${documentId}/assign`, data)
      .then((response) => response.data);
  }

  async generateDocumentsGroupZip(applicationId: string, groupId: string, fileName: string) {
    const response = await RequestService.client.get<Blob>(
      `/applications/${applicationId}/documents-group-zipped/${groupId}`,
      {
        responseType: 'blob',
      },
    );

    fileDownload(response.data, `${fileName}.zip`);
  }

  async generateExternalScanReport(
    applicationId: string,
    orgName: string,
    type: 'executive' | 'detailed',
    format: 'pdf' | 'docx',
  ) {
    const response = await RequestService.client.get<Blob>(
      `/applications/${applicationId}/generate-external-scan-report?includeFindings=${
        type === 'detailed'
      }&format=${format}`,
      {
        responseType: 'blob',
      },
    );

    if (type === 'detailed') {
      fileDownload(response.data, `${orgName} - External Scan Detailed Report-${+new Date()}.${format}`);
    } else {
      fileDownload(response.data, `${orgName} - External Scan Executive Report-${+new Date()}.${format}`);
    }
  }

  async getApplicationQuoteDocuments(applicationId: string, quoteId: string) {
    return await RequestService.client
      .get<QuoteDocumentDTO[]>(`/applications/${applicationId}/quotes/${quoteId}/documents`)
      .then((response) => response.data);
  }

  async downloadApplicationDocument(applicationId: string, document: ApplicationDocumentDTO) {
    const signedDownloadUrl = await RequestService.client
      .get<string>(`/applications/${applicationId}/documents/${document.id}`)
      .then((response) => response.data);

    const response = await RequestService.client.get<Blob>(signedDownloadUrl, {
      responseType: 'blob',
    });

    fileDownload(response.data, document.fileName);
  }

  async uploadApplicationDocument(applicationId: string, type: ApplicationDocumentType, file: File) {
    const data = await RequestService.client
      .post<GeneratedUploadUrlResponseDTO>(`/applications/${applicationId}/upload-document`, { fileType: type })
      .then((response) => response.data);

    await RequestService.uploadFile(file, data);

    return data;
  }

  async createApplicationDocument(applicationId: string, data: CreateApplicationDocumentDTO) {
    return await RequestService.client
      .post<ApplicationDocumentDTO>(`/applications/${applicationId}/documents`, data)
      .then((response) => response.data);
  }

  async generateApplicationDocument(applicationId: string, data: GenerateApplicationDocumentDTO) {
    return await RequestService.client
      .post<ApplicationDocumentDTO>(`/applications/${applicationId}/documents/generate`, data)
      .then((response) => response.data);
  }

  async quotesCount(applicationId: string) {
    return await RequestService.client
      .get<ApplicationQuotesCount>(`/applications/${applicationId}/quotes-count`)
      .then((response) => response.data);
  }

  async deleteApplicationDocument(applicationId: string, documentId: string) {
    return await RequestService.client
      .delete<void>(`/applications/${applicationId}/documents/${documentId}`)
      .then((response) => response.data);
  }

  async createManualQuote(applicationId: string, data: CreateManualQuoteDTO, quoteLetter?: File): Promise<QuoteDTO> {
    const quote: QuoteDTO = await RequestService.client
      .post<QuoteDTO>(`/applications/${applicationId}/quotes`, data)
      .then((response) => response.data);

    if (quoteLetter) {
      const data = await QuoteService.uploadQuoteDocument(quote.id, QuoteDocumentType.QUOTE_LETTER, quoteLetter);
      await QuoteService.createQuoteDocument(quote.id, {
        documentPath: data.path,
        fileName: quoteLetter.name,
        documentType: QuoteDocumentType.QUOTE_LETTER,
      });
    }

    return quote;
  }

  createManualDeclination(applicationId: string, data: CreateManualDeclinationDTO): Promise<DeclinationDTO> {
    return RequestService.client
      .post<DeclinationDTO>(`/applications/${applicationId}/declinations`, data)
      .then((response) => response.data);
  }

  async downloadProposalPdf(
    applicationId: string,
    businessName: string,
    proposal: ProposalDTO,
    fileName?: string,
  ): Promise<void> {
    return this.downloadProposalDocument(applicationId, businessName, proposal, 'pdf', fileName);
  }

  async downloadProposalDocx(
    applicationId: string,
    businessName: string,
    proposal: ProposalDTO,
    fileName?: string,
  ): Promise<void> {
    return this.downloadProposalDocument(applicationId, businessName, proposal, 'docx', fileName);
  }

  private async downloadProposalDocument(
    applicationId: string,
    businessName: string,
    proposal: ProposalDTO,
    format: 'docx' | 'pdf',
    fileName?: string,
  ) {
    const signedDownloadUrl = await RequestService.client
      .get<string>(`/applications/${applicationId}/proposals/${proposal.id}/${format}`)
      .then((response) => response.data);

    const response = await RequestService.client.get<Blob>(signedDownloadUrl, {
      responseType: 'blob',
    });

    fileDownload(
      response.data,
      fileName || `Proposal-${businessName.replaceAll(' ', '-')}-${dayjs().format('DD-MMM-YYYY')}.${format}`,
    );
  }

  getApplicationDnbResults(applicationId: string): Promise<ApplicationRawDnbDTO> {
    return RequestService.client
      .get<ApplicationRawDnbDTO>(`/applications/dnb/${applicationId}`)
      .then((response) => response.data);
  }

  getApplicationBreachData(applicationId: string): Promise<ApplicationBreachDataDTO[]> {
    return RequestService.client
      .get<ApplicationBreachDataDTO[]>(`/applications/${applicationId}/breach-data`)
      .then((response) => response.data);
  }

  prefillApplicationUsingDunes(applicationId: string, dunes: string): Promise<ApplicationDTO> {
    return RequestService.client
      .post<ApplicationDTO>(`/applications/dnb/${applicationId}/${dunes}`)
      .then((response) => response.data);
  }

  skipDuns(applicationId: string): Promise<ApplicationDTO> {
    return RequestService.client
      .patch<ApplicationDTO>(`/applications/dnb/${applicationId}/skip-duns`)
      .then((response) => response.data);
  }

  updateApplicationSettings(applicationId: string, data: UpdateApplicationSettingDTO): Promise<ApplicationDTO> {
    return RequestService.client
      .patch<ApplicationDTO>(`/applications/${applicationId}/settings`, data)
      .then((response) => response.data);
  }

  getLatestActiveProposal(applicationId: string) {
    return RequestService.client
      .get<ProposalWithApplicationDTO>(`/applications/${applicationId}/latest-proposal`)
      .then((response) => response.data);
  }

  chooseApplicationQuote(id: string, quoteId: string) {
    return RequestService.client
      .post<PolicyDTO>(`/applications/client/${id}/bind/${quoteId}`)
      .then((response) => response.data);
  }

  createTarget(data: CreateTargetDTO) {
    return RequestService.client.post<TargetDTO>(`/targets`, data).then((response) => response.data);
  }

  getSecurityApplicationVersion() {
    return RequestService.client
      .get<ApplicationVersionDTO>('applications/security-application-version/')
      .then((response) => response.data);
  }

  getFinancialRiskCalculatorApplicationVersion() {
    return RequestService.client
      .get<ApplicationVersionDTO>('applications/financial-risk-calculator-application-version/')
      .then((response) => response.data);
  }

  getUserSignupApplicationVersion() {
    return RequestService.client
      .get<ApplicationVersionDTO>('applications/user-signup-application-version/')
      .then((response) => response.data);
  }
}

const ApplicationService = new _ApplicationService();

export { ApplicationService };
