import { Button } from '@material-ui/core';
import { Modal } from 'components/Modal';
import { AgentDTO } from 'dtos/agent';
import { useToasters } from 'hooks/useToasters';
import { useResetMfaForUser } from 'queries/useAgency';
import React from 'react';

interface Props {
  agent: AgentDTO;
}

export const ResetAgentMfaModal = ({ agent }: Props) => {
  const [open, setOpen] = React.useState(false);
  const { showToaster, toasterErrorHandler } = useToasters();

  const { mutate, isLoading } = useResetMfaForUser(agent.agencyId || '', {
    onSuccess: () => {
      showToaster(`Successfully reset MFA for ${agent.email}`);
      setOpen(false);
    },
    onError: (e) => toasterErrorHandler(e),
  });

  const onSubmit = () => {
    mutate({ userId: agent.id });
  };

  return (
    <>
      <Modal
        title='Reset MFA'
        isOpen={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        openButton={
          <Button onClick={() => setOpen(true)} className='!py-0'>
            Reset MFA
          </Button>
        }
      >
        <div className='-mt-4'>
          <p className='m-0 mb-4'>
            Are you sure you want to reset the MFA for <strong>{agent.email}</strong>? This action cannot be undone.
          </p>

          <Button type='submit' color='secondary' variant='contained' disabled={isLoading} onClick={onSubmit}>
            {isLoading ? 'Loading...' : 'Reset MFA'}
          </Button>
        </div>
      </Modal>
    </>
  );
};
