import axios, { AxiosInstance, InternalAxiosRequestConfig } from 'axios';
import { GeneratedUploadUrlResponseDTO } from 'dtos/_common';
import { loadAuthTokens } from 'services/auth0-tokens';
import { API_URL } from 'telivy-constants';

export class RequestService {
  client: AxiosInstance;

  constructor() {
    this.client = axios.create({
      baseURL: API_URL,
    });

    this.setAuthenticationInterceptor();
  }

  setAuthenticationInterceptor() {
    this.client.interceptors.request.use((config: InternalAxiosRequestConfig) => {
      const tokens = loadAuthTokens();

      // Prevent from extra headers to Google APIs resulting in CORS issues
      if (config.url && config.url.includes('googleapis')) {
        return config;
      }

      if (tokens.accessToken) {
        // config.headers = { ...config.headers } as AxiosRequestHeaders;
        config.headers.set('Authorization', 'Bearer ' + tokens.accessToken);
      }

      if (tokens.adminToken) {
        // config.headers = { ...config.headers } as AxiosRequestHeaders;
        config.headers.set('Authorization-Admin', 'Bearer ' + tokens.adminToken);
      }

      return config;
    });

    this.client.interceptors.response.use(
      (response) => response,
      async (error) => {
        // console.log('HERE');
        if (error.response.status === 401) {
          // retry only once
          if (!error.config.headers.get('Authorization-Retry')) {
            try {
              // Attempt to refresh the access token
              const tokens = loadAuthTokens();

              // Update the request with the new token
              if (tokens.accessToken) {
                // config.headers = { ...config.headers } as AxiosRequestHeaders;
                error.config.headers.set('Authorization', 'Bearer ' + tokens.accessToken);
                error.config.headers.set('Authorization-Retry', 'Intercepted Retry');
              }

              // Retry the original request
              return this.client(error.config);
            } catch (refreshError) {
              // console.log('REDIRECT');

              // Reload current page
              return window.location.reload();
            }
          } else {
            // console.log('REDIRECT');

            // Reload current page
            return window.location.reload();
          }
        }

        return Promise.reject(error);
      },
    );
  }

  // https://github.com/googleapis/nodejs-storage/issues/347
  async uploadFile(file: File, data: GeneratedUploadUrlResponseDTO) {
    return new Promise((res, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.open('PUT', data.url);
      xhr.onload = () => {
        res(data.path);
      };
      xhr.onerror = (err) => {
        reject(err);
      };
      xhr.setRequestHeader('Content-Type', 'application/octet-stream');
      xhr.send(file);
    });
  }
}

export default new RequestService();
