import { useAuth0 } from '@auth0/auth0-react';
import { Button, MenuItem, Select, TextField, Typography } from '@material-ui/core';
import { AgentRole } from 'dtos/agent';
import { useAllAgents, useCurrentAgent } from 'queries/useAgent';
import React, { useCallback, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import { clearAuthTokens, setAdminAuthToken } from 'services/auth0-tokens';
import { ROUTES } from 'telivy-constants';

export const AdminView: React.FC = () => {
  const navigate = useNavigate();
  const { isAuthenticated } = useAuth0();
  const [secret, setSecret] = useState('');
  const [error, setError] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [userSub, setUserSub] = useState('auth0|');
  const { refetch, data: user } = useCurrentAgent({
    enabled: isAuthenticated,
    retry: false,
    onError: () => {
      setError('Incorrect data.');
      clearAuthTokens(true);
    },
  });

  const { data } = useAllAgents({
    enabled: isAuthenticated,
  });

  const handleSubmit = useCallback(() => {
    setError('');
    setAdminAuthToken(secret, userSub);
    refetch();
    navigate(ROUTES.agent.ROOT);
  }, [navigate, refetch, secret, userSub]);

  return (
    <div className='min-h-screen flex items-center justify-center bg-gray-50'>
      <Helmet>
        <title>Admin</title>
      </Helmet>

      <div className='w-96 bg-white p-8 rounded-2xl shadow-lg space-y-6'>
        <Typography variant='h3' className='text-center text-gray-900'>
          Admin Login
        </Typography>

        <div className='space-y-6'>
          <div>
            <TextField
              fullWidth
              label='Secret'
              value={secret}
              variant='outlined'
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setSecret(e.target.value)}
              className='bg-white'
            />
            <p className='m-0 text-gray-500 text-xs mt-1'>Secret known only to the staff.</p>
          </div>

          {isAuthenticated && user?.role === AgentRole.TELIVY_ADMIN && (
            <div>
              <Select
                placeholder='Select user'
                className='w-full border py-2 px-4 border-solid border-gray-200 rounded-md'
                value={userSub}
                onChange={(el) => {
                  setUserSub(el.target.value as string);
                }}
                onOpen={() => setSearchTerm('')}
              >
                <MenuItem>
                  <TextField
                    size='small'
                    placeholder='Search users...'
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    onClick={(e) => e.stopPropagation()}
                    onKeyDown={(e) => e.stopPropagation()}
                    fullWidth
                  />
                </MenuItem>
                {data
                  ?.filter((agent) => agent.email.toLowerCase().includes(searchTerm.toLowerCase()))
                  .map((agent) => (
                    <MenuItem key={agent.id} value={agent.userToken}>
                      {agent.email}
                    </MenuItem>
                  ))}
              </Select>
              <p className='m-0 text-gray-500 text-xs mt-1'>Select user to impersonate (optional)</p>
            </div>
          )}

          <div>
            <TextField
              fullWidth
              value={userSub}
              label='User sub'
              variant='outlined'
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setUserSub(e.target.value)}
              className='bg-white'
            />
            <p className='m-0 text-gray-500 text-xs mt-1'>Should start with &apos;auth0|&apos;.</p>
          </div>
        </div>

        <Button fullWidth color='primary' variant='contained' onClick={handleSubmit} className='!py-3'>
          Submit
        </Button>

        {error && (
          <Typography variant='caption' className='block text-center text-red-500 mt-2'>
            {error}
          </Typography>
        )}
      </div>
    </div>
  );
};
