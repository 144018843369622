import { isAgentAnAdmin } from 'telivy-selectors';

import { ApplicationDTO } from '../dtos/application';
import { DeepScanDTO } from '../dtos/deep-scan';
import { useCurrentAgent } from '../queries/useAgent';

export const useChartsEnabled = (deepScan?: DeepScanDTO, application?: ApplicationDTO) => {
  const { data } = useCurrentAgent();

  return (
    isAgentAnAdmin(data) || data?.agency?.enableCharts || deepScan?.enableCharts || application?.isDeepScan || false
  );
};

export const useRiskMonitoringEnabled = () => {
  const { data } = useCurrentAgent();

  return data?.agency?.enableRiskMonitoring;
};
