import { AlertPreference } from 'dtos/alert';
import { FindingSeverity } from 'dtos/application';
import { PiiConfig } from 'dtos/deep-scan';

export enum AgentRole {
  TELIVY_ADMIN = 'telivy_admin',
  AGENCY_ADMIN = 'agency_admin',
  REGULAR = 'regular',
}

export enum AgencyType {
  RETAIL_AGENT = 'RETAIL_AGENT',
  EMBEDDED_CHANNEL = 'EMBEDDED_CHANNEL',
  SECURITY_DASHBOARD = 'SECURITY_DASHBOARD',
}

export interface AgencyDTO {
  id: string;
  name: string;
  domain: string;
  apiKey: string;
  logoUrl: string;
  hideBillingForChildAgencies: boolean;
  hideBilling: boolean;
  redirectLink: string;
  activatedAt: string;
  trialStartedAt: string;
  trialDays: number;
  agencyType: AgencyType;
  enableRiskAssessmentsPlus: boolean;
  enableCharts: boolean;
  enableLeadMagnet: boolean;
  enablePassphrase: boolean;
  disableExternalScan: boolean;
  disableRiskAssessment: boolean;
  enableRiskMonitoring: boolean;
  enableGoogleWorkspace: boolean;
  enableCrm: boolean;
  enableTopology: boolean;
  enableAlerts: boolean;
  enableRemediation: boolean;
  isMasterAgency: boolean;
  overrwriteChildAgencyLogos: boolean;
  disallowCreatingAssesmentForChildAgencies: boolean;
  masterAgencyId: string;
  subAgencies?: AgencyDTO[];
  nodewareApiKey: string | null;
  piiConfig: PiiConfig;
}

export interface CreateEditAgentInAgencyDTO {
  email: string;
  role: AgentRole;
  password: string;
  firstName: string;
  lastName: string;
  agencyId?: string | null;
}

export interface CreateUpdateAgencyDTO {
  name?: string;
  redirectLink?: string | null;
  email?: string;
  password?: string;
  firstName?: string;
  lastName?: string;
  createFirstUser?: boolean;
  nodewareApiKey?: string | null;
  piiConfig?: PiiConfig;
}

export interface DeleteAgentInAgencyDTO {
  transferAgentId: string;
  email: string;
}

export interface AgencyStatsDTO {
  id: string;
  name: string;
  domain: string;
  totalUsers: number;
  totalExternalScans: number;
  totalRiskAssessments: number;
  totalRiskAssessmentPluses: number;
  totalActiveDeepScanAssets: number;
  totalActiveLightScanAssetsCount: number;
}

export interface BaseAgentDTO {
  id: string;
  email: string;
  agency: AgencyDTO | null;
  agencyId: string | null;
  agencyName: string | null;
}

export interface AgentDTO extends BaseAgentDTO {
  firstName: string | null;
  lastName: string | null;
  role: AgentRole;
  userToken: string;
  phone: string | null;
  isEmailVerified: boolean;
  securityEnabled: boolean;
  deepScanEnabled: boolean;
  createdAt: Date;
  updatedAt: Date;
  externalScanCount: number;
  riskAssessmentCount: number;
  riskAssessmentPlusCount: number;
  activeDeepScanAssetsCount: number;
  activeLightScanAssetsCount: number;
  enableRiskAssessmentsPlus: boolean;
  masterAgency?: AgencyDTO | null;
  subAgencies?: AgencyDTO[];
  alertPreferences: AlertPreference[] | null;
  masterLogo?: string | null;
}

export interface AgentSettingsDTO {
  IMPORT_CSV_DOMAINS_LIMIT: number | null;
}

export interface AgentSummaryDTO {
  summary: {
    companies: number;
    domains: number;
    findings: number;
  };

  domainsByFindings: Array<{
    name: string;
    numberOfFindings: number;
  }>;

  findingsByDomains: Array<{
    name: string;
    numberOfDomains: number;
    severity: FindingSeverity;
  }>;

  overallScoresByDomain: Record<string, number>;
}
