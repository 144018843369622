import { Button } from '@material-ui/core';
import { Modal } from 'components/Modal';
import { AgencyStatsDTO } from 'dtos/agent';
import { useToasters } from 'hooks/useToasters';
import { useDeleteAgency } from 'queries/useAgent';
import React from 'react';

interface Props {
  agency: AgencyStatsDTO;
}

export const DeleteAgency = ({ agency }: Props) => {
  const [open, setOpen] = React.useState(false);
  const { showToaster, toasterErrorHandler } = useToasters();

  const { mutate, isLoading } = useDeleteAgency({
    onSuccess: () => {
      showToaster(`Successfully deleted account`);
      setOpen(false);
    },
    onError: (e) => toasterErrorHandler(e),
  });

  const onSubmit = () => {
    mutate({ id: agency.id });
  };

  return (
    <>
      <Modal
        title={`Are you sure you want to delete ${agency.name}?`}
        isOpen={open}
        onClose={() => setOpen(false)}
        openButton={
          <Button
            variant={agency ? 'outlined' : 'contained'}
            color={agency ? 'default' : 'primary'}
            className='!py-[0.2px]'
            onClick={() => setOpen(true)}
            disabled={agency.totalUsers > 0}
          >
            Delete account
          </Button>
        }
      >
        <div>
          <p className='m-0'>
            This action is irreversible. All data associated with this account will be permanently deleted.
          </p>
          <Button
            type='submit'
            color='secondary'
            variant='contained'
            disabled={isLoading}
            onClick={onSubmit}
            className='!mt-4 block'
          >
            Yes, delete account
          </Button>
        </div>
      </Modal>
    </>
  );
};
