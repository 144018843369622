import { AgentDTO } from 'dtos/agent';
import React from 'react';
import { isAgentAnAnyAdmin } from 'telivy-selectors';
import { ConfigurePiiCostsModal } from 'templates/SecurityAssessments/components/ConfigurePiiCostsModal';

interface Props {
  agent: AgentDTO;
}

export const AssessmentsView: React.FC<Props> = ({ agent }) => {
  return (
    <div>
      <div className='max-w-4xl'>
        <div className='flex justify-between items-center mb-4'>
          <h1 className='text-2xl m-0 font-bold text-gray-900'>Assessments</h1>
        </div>

        <div className='space-y-8'>
          <div className='bg-white rounded-lg shadow p-6'>
            <div>
              <h3 className='text-lg mt-0 font-semibold text-gray-900 mb-3'>PII capture</h3>
              <p className='text-gray-600 mb-6'>
                Manage the PII capture settings for your agency. This will be the default settings for all new
                assessments.
              </p>

              {agent.agency && isAgentAnAnyAdmin(agent) && (
                <ConfigurePiiCostsModal
                  entityId={agent.agency.id}
                  type='agency'
                  defaultValues={agent.agency.piiConfig}
                  label='Confugure default PII Settings'
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
