import { Checkbox, makeStyles } from '@material-ui/core';
import CalendarTodayRoundedIcon from '@material-ui/icons/CalendarTodayRounded';
import CheckCircleOutlineRoundedIcon from '@material-ui/icons/CheckCircleOutlineRounded';
import CreateIcon from '@material-ui/icons/Create';
import InsertDriveFileOutlinedIcon from '@material-ui/icons/InsertDriveFileOutlined';
import WorkOutlineRoundedIcon from '@material-ui/icons/WorkOutlineRounded';
import { ApplicationStatusLabel } from 'components/ApplicationStatusLabel';
import { ScanningStatusLabel } from 'components/ApplicationStatusLabel/ScanningStatusLabel';
import { EffectiveDate } from 'components/EffectiveDate';
import { Column, SortOrder, Table } from 'components/Table';
import { VerifyEmail } from 'components/VerifyEmail';
import dayjs from 'dayjs';
import { AgentDTO } from 'dtos/agent';
import {
  ApplicationDTO,
  ApplicationInsuranceLineQueryOptions,
  ApplicationOrigin,
  ApplicationsAdminResponse,
  ApplicationSortBy,
  ApplicationsQuery,
  ApplicationsResponse,
  ApplicationStatus,
  ApplicationWithAgentDTO,
  MAP_ORIGIN_TO_LABEL,
} from 'dtos/application';
import { ClientStatus } from 'dtos/company';
import { intersection, omit, startCase } from 'lodash';
import { useCurrentAgencyUsers } from 'queries/useAgency';
import {
  useAdminApplications,
  useApplication,
  useApplications,
  useSecurityApplicationVersion,
} from 'queries/useApplications';
import queryString from 'query-string';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { UseQueryResult } from 'react-query';
import { useLocation, useNavigate } from 'react-router-dom';
import { DEMO_ID, insertIdToRoute, ROUTES } from 'telivy-constants';
import { mapScanningStatus } from 'telivy-maps';
import {
  isAgentAGlobalAdmin,
  isAgentAnAdmin,
  isAgentAnAgencyAdmin,
  isAgentAnAnyAdmin,
  isSecurityAgency,
} from 'telivy-selectors';
import { ClientStatusLabel } from 'views/agent/views/crm/ClientStatusLabel';

import { ExportCsvButton } from '../application-details/views/security/ExportCsvButton';
import { AvailableFilter, Filters, QueryParams } from './Filters';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    padding: `${theme.spacing()}px ${theme.spacing(4)}px ${theme.spacing(3)}px ${theme.spacing(4)}px`,
    boxSizing: 'border-box',
  },
  filters: {
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  overallScoreBadge: {
    marginRight: theme.spacing(1),
  },
  checkbox: {
    padding: `0 ${theme.spacing(1)}px`,
  },
}));

const generateColumns = (
  isAgentAnAdmin: boolean,
  isAgentAGlobalAdmin: boolean,
  isAgentAnAgencyAdmin: boolean,
  isSecurityAgency?: boolean,
  enableLeadMagnet?: boolean,
  securityVersionId?: string,
  // badgeClassName?: string,
  selectedIds?: string[],
  data?: (ApplicationDTO | ApplicationWithAgentDTO)[],
  setSelectedIds?: (ids: string[]) => void,
  checkboxClassName?: string,
  isAgencyAdmin?: boolean,
): Array<Column<ApplicationDTO | ApplicationWithAgentDTO, ApplicationSortBy>> => {
  const remainingSpacePercentages = 80;
  const statusColumnWidth = 150;
  const isSecurityVersion = (app: ApplicationDTO | ApplicationWithAgentDTO) =>
    app.applicationVersionId === securityVersionId;

  const baseColumns: Array<Column<ApplicationDTO | ApplicationWithAgentDTO, ApplicationSortBy>> = [
    {
      title: 'Client Status',
      width: `100px`,
      // sortKey: 'company.clientStatus',
      render: (application) =>
        application.company?.clientStatus && (
          <ClientStatusLabel clientStatus={application.company.clientStatus} fullWidth />
        ),
    },
    {
      title: 'Client Name and Domain',
      width: '20%',
      sortKey: ApplicationSortBy.ORGANIZATION_NAME,
      render: (application) => (
        <>
          {application.applicationResponse.organization_name}
          <br />
          {application.applicationResponse.domain_prim}
        </>
      ),
    },
  ];

  if (isSecurityAgency || isAgentAnAdmin || isAgentAGlobalAdmin) {
    baseColumns.push({
      title: 'Assessment Status',
      width: `${statusColumnWidth}px`,
      sortKey: ApplicationSortBy.STATUS,
      render: (application) => {
        if (application.deepScanId) {
          return <ApplicationStatusLabel status={application.applicationStatus} fullWidth isAdmin={isAgentAnAdmin} />;
        } else {
          return (
            <ScanningStatusLabel
              status={mapScanningStatus(application.securityScan?.status)}
              fullWidth
              isAdmin={isAgentAnAdmin}
            />
          );
        }
      },
    });

    baseColumns.push({
      title: 'Assessment Type',
      width: `calc(X)`,
      render: (app) => {
        if (isSecurityVersion(app) && app.isLightScan) {
          return 'Risk Assessment';
        }

        if (isSecurityVersion(app) && app.isDeepScan) {
          return 'Risk Assessment Plus';
        }

        return isSecurityVersion(app) ? `External Assessment` : 'Risk Assessment';
      },
    });
  }

  // if (isSecurityAgency || isAgentAnAdmin || isAgentAGlobalAdmin) {
  //   baseColumns.push({
  //     title: 'Company Domain',
  //     width: `calc(X)`,
  //     render: (application) => (
  //       <>
  //         {<OverallScoreBadge
  //           variant='small'
  //           securityOverallScore={application.securityOverallScore}
  //           className={badgeClassName}
  //         />}
  //         {application.applicationResponse.domain_prim}
  //       </>
  //     ),
  //   });
  // }

  baseColumns.push({
    title: 'Created',
    width: `calc(X)`,
    sortKey: ApplicationSortBy.CREATED_AT,
    render: (application) => dayjs(application.createdAt).format('M/D/YYYY'),
  });

  if (!isSecurityAgency) {
    baseColumns.push({
      title: 'Effective date',
      width: `calc(X)`,
      sortKey: ApplicationSortBy.TARGET_EFFECTIVE_DATE,
      render: (application) => !isSecurityVersion(application) && <EffectiveDate table application={application} />,
    });
  } else if (enableLeadMagnet || isAgentAnAdmin || isAgentAGlobalAdmin) {
    baseColumns.push({
      title: 'Source',
      width: `calc(X)`,
      render: (application) => MAP_ORIGIN_TO_LABEL[application.origin],
    });

    // baseColumns.push({
    //   title: 'UTM Source',
    //   width: `calc(X)`,
    //   render: (application) => application.utmSource?.replace('Unknown', 'N/A'),
    // });
  }

  if (isSecurityAgency || isAgentAnAdmin || isAgentAGlobalAdmin) {
    // baseColumns.push({
    //   title: 'Scanning Status',
    //   width: `calc(X)`,
    //   render: (application) => mapScanningStatus(application.securityScan?.status),
    // });

    // baseColumns.push({
    //   title: 'Subdomains',
    //   width: `calc(X)`,
    //   render: (application) => application.securityScan?.subDomains?.length,
    // });

    // baseColumns.push({
    //   title: 'Monitoring',
    //   width: `calc(X)`,
    //   render: (application) => (application.monitoringEnabled ? 'Active' : 'Inactive'),
    // });

    baseColumns.push({
      title: 'Assets',
      width: `calc(X)`,
      render: (application) => application.__deepScan__?.activeAssetsCount,
    });
  }

  if (isAgentAnAdmin || isAgentAGlobalAdmin) {
    baseColumns.push({
      title: 'User and Account',
      width: `calc(X)`,
      sortKey: ApplicationSortBy.BROKER,
      render: (application) => (
        <>
          {'agent' in application ? application.agent.email : ''}
          <br />
          {'agent' in application ? application.agent.agency?.name : ''}
        </>
      ),
    });
  } else if (isAgentAnAgencyAdmin) {
    baseColumns.push({
      title: 'User',
      width: `calc(X)`,
      sortKey: ApplicationSortBy.BROKER,
      render: (application) => ('agent' in application ? application.agent.email : ''),
    });
  }

  // if (isAgentAnAdmin || !isSecurityAgency) {
  // baseColumns.unshift({
  //   title: 'Assessment Status',
  //   width: `${statusColumnWidth}px`,
  //   sortKey: ApplicationSortBy.STATUS,
  //   render: (application) => {
  //     if (application.deepScanId) {
  //       return <ApplicationStatusLabel status={application.applicationStatus} fullWidth isAdmin={isAgentAnAdmin} />;
  //     } else {
  //       return (
  //         <ScanningStatusLabel
  //           status={mapScanningStatus(application.securityScan?.status)}
  //           fullWidth
  //           isAdmin={isAgentAnAdmin}
  //         />
  //       );
  //     }
  //   },
  // });
  // }

  if (data?.length && setSelectedIds && isAgencyAdmin) {
    baseColumns.unshift({
      title: '',
      width: 20,
      render: (v) => {
        return (
          <Checkbox
            color='primary'
            checked={(selectedIds || []).includes(v.id)}
            inputProps={{ 'aria-labelledby': `select ${v.id}` }}
            className={checkboxClassName}
            onClick={() => {
              if ((selectedIds || []).includes(v.id)) {
                setSelectedIds((selectedIds || []).filter((s) => s !== v.id));
              } else {
                setSelectedIds([...(selectedIds || []), v.id]);
              }
            }}
          />
        );
      },
      renderColumn: () => {
        return (
          <Checkbox
            color='primary'
            checked={(selectedIds || []).length === (data || []).length}
            indeterminate={(selectedIds || []).length > 0 && (selectedIds || []).length < (data || []).length}
            inputProps={{ 'aria-labelledby': 'select all' }}
            className={checkboxClassName}
            onChange={() => {
              if ((selectedIds || []).length === (data || []).length) {
                setSelectedIds([]);
              } else {
                setSelectedIds((data || []).map((d) => d.id));
              }
            }}
          />
        );
      },
    });
  }

  return baseColumns.map((column) => ({
    ...column,
    width:
      column.width === 'calc(X)'
        ? `calc(${remainingSpacePercentages / baseColumns.length}% - ${statusColumnWidth}px)`
        : column.width,
  }));
};

interface Props {
  agent: AgentDTO;
}

declare const window: any;

const PER_PAGE = 15;
export const AgentDashboardView = ({ agent }: Props) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { search } = useLocation();
  const { data: securityApplicationVersion } = useSecurityApplicationVersion();
  const isAdmin = isAgentAnAdmin(agent);
  const isGlobalAdmin = isAgentAGlobalAdmin(agent);
  const isAgencyAdmin = isAgentAnAnyAdmin(agent);

  const [selectedIds, setSelectedIds] = useState<string[]>([]);

  const { data: allUsers } = useCurrentAgencyUsers();

  useEffect(() => {
    // identify hotjar
    if (window.hj && !window['hj_identified']) {
      window['hj_identified'] = true;
      window.hj('identify', null, {
        email: agent.email,
        msp_name: agent.agency?.name,
        status: agent.agency?.activatedAt ? 'active' : !agent.agency?.enableCharts ? 'suspended' : 'trial',
        createdAt: agent.createdAt,
      });
    }
  });

  const filtersList: AvailableFilter<QueryParams> = useMemo(() => {
    const assessmentTypeOptions = [];
    if (isSecurityAgency(agent) || isAdmin || isGlobalAdmin) {
      assessmentTypeOptions.push({
        label: 'External Assessment',
        value: ApplicationInsuranceLineQueryOptions.SECURITY,
      });
      assessmentTypeOptions.push({
        label: 'Risk Assessment',
        value: ApplicationInsuranceLineQueryOptions.SECURITY_LIGHT_SCAN,
      });
      if (isAdmin) {
        assessmentTypeOptions.push({
          label: 'Risk Assessment Plus',
          value: ApplicationInsuranceLineQueryOptions.SECURITY_DEEP_SCAN,
        });
      }
    }
    if (!isSecurityAgency(agent) || isAdmin) {
      assessmentTypeOptions.push({ label: 'Cyber', value: ApplicationInsuranceLineQueryOptions.CYBER });
      assessmentTypeOptions.push({ label: 'Tech E&O', value: ApplicationInsuranceLineQueryOptions.TECH_EO });
      assessmentTypeOptions.push({ label: 'General', value: ApplicationInsuranceLineQueryOptions.GENERAL });
    }

    const filters = {
      agencyId: {
        label: 'Account',
        icon: <WorkOutlineRoundedIcon />,
        options: (agent.subAgencies || []).map((account) => ({
          label: `${account.name}${account.name != account.domain ? ` (${account.domain})` : ''}`,
          value: account.id,
        })),
      },
      agentId: {
        label: 'User',
        icon: <WorkOutlineRoundedIcon />,
        options: (allUsers || []).map((user) => ({
          label: `${user.firstName} ${user.lastName} (${user.email})`,
          value: user.id,
        })),
      },
      status: {
        label: 'Status',
        icon: <CheckCircleOutlineRoundedIcon />,
        options: Object.values(ApplicationStatus).map((status) => ({
          label: startCase(status),
          value: status || null || undefined,
        })),
      },
      origin: {
        label: 'Source',
        icon: <CreateIcon />,
        options: Object.values(ApplicationOrigin)
          .filter(
            (o) =>
              ![
                ApplicationOrigin.BULK,
                ApplicationOrigin.TEST,
                ApplicationOrigin.STRIPE,
                ApplicationOrigin.GLOBAL_ADMIN,
              ].includes(o),
          )
          .map((origin) => ({
            label: MAP_ORIGIN_TO_LABEL[origin],
            value: origin || null || undefined,
          })),
      },
      insuranceLine: {
        label: isSecurityAgency(agent) || isAdmin || isGlobalAdmin ? 'Assessment Type' : 'Insurance Line',
        icon: <CreateIcon />,
        options: assessmentTypeOptions,
      },
      showBulk: {
        label: 'Show bulk',
        icon: <InsertDriveFileOutlinedIcon />,
        options: [
          { label: 'True', value: true },
          { label: 'False', value: false },
        ],
      },
      showMonitored: {
        label: 'Monitoring Enabled',
        icon: <CheckCircleOutlineRoundedIcon />,
        options: [
          { label: 'True', value: true },
          { label: 'False', value: false },
        ],
      },
      creationDateDays: {
        label: 'Creation Date',
        icon: <CalendarTodayRoundedIcon />,
        options: [
          { label: 'In the last 7 days', value: 7 },
          { label: 'In the last 14 days', value: 14 },
          { label: 'In the last 21 days', value: 21 },
          { label: 'In the last 30 days', value: 30 },
        ],
      },
      effectiveDateDays: {
        label: 'Effective Date',
        icon: <CalendarTodayRoundedIcon />,
        options: [
          { label: 'In the next 7 days', value: 7 },
          { label: 'In the next 14 days', value: 14 },
          { label: 'In the next 21 days', value: 21 },
          { label: 'In the next 30 days', value: 30 },
        ],
      },
      clientStatus: {
        label: 'Client Status',
        icon: <CheckCircleOutlineRoundedIcon />,
        options: [
          { label: 'Prospect', value: ClientStatus.LEAD },
          { label: 'Client', value: ClientStatus.CLIENT },
        ],
      },
    };

    if (isSecurityAgency(agent) && !isAdmin && !isGlobalAdmin) {
      return omit(filters, ['agencyId', 'status', 'showBulk', 'effectiveDateDays']);
    } else if (isSecurityAgency(agent) && isGlobalAdmin) {
      return omit(filters, ['status', 'showBulk', 'effectiveDateDays', 'showMonitored']);
    } else if (isSecurityAgency(agent) && isAdmin) {
      return omit(filters, ['agencyId', 'account', 'showBulk', 'effectiveDateDays']);
    } else if (!isAdmin) {
      return omit(filters, ['showMonitored']);
    }

    return filters;
  }, [agent, isAdmin, isGlobalAdmin, allUsers]);

  const queryParams = useMemo(
    () =>
      queryString.parse(search, {
        parseNumbers: true,
      }) as unknown as QueryParams,
    [search],
  );

  // default params values
  const params = useMemo(
    () => ({
      q: queryParams.q || '',
      page: queryParams.page || 0,
      status: queryParams.status,
      origin: queryParams.origin,
      sortBy: queryParams.sortBy || ApplicationSortBy.CREATED_AT,
      sortOrder: queryParams.sortOrder || SortOrder.DESC,
      showBulk: queryParams.showBulk || false,
      showMonitored: queryParams.showMonitored || false,
      effectiveDateDays: queryParams.effectiveDateDays,
      clientStatus: queryParams.clientStatus,
      creationDateDays: queryParams.creationDateDays,
      insuranceLine: queryParams.insuranceLine,
      agencyId: queryParams.agencyId,
      agentId: queryParams.agentId,
    }),
    [queryParams],
  );

  const fnHook: (query: ApplicationsQuery) => UseQueryResult<ApplicationsAdminResponse | ApplicationsResponse> = isAdmin
    ? useAdminApplications
    : useApplications;
  const { data, isLoading } = fnHook({
    q: params.q,
    limit: PER_PAGE,
    offset: params.page * PER_PAGE,
    status: params.status || undefined,
    origin: params.origin || undefined,
    sortBy: params.sortBy,
    sortOrder: params.sortOrder,
    showBulk: params.showBulk,
    showMonitored: params.showMonitored,
    clientStatus: params.clientStatus as ClientStatus,
    effectiveDateDays: params.effectiveDateDays,
    creationDateDays: params.creationDateDays,
    insuranceLine: params.insuranceLine,
    agencyId: params.agencyId,
    agentId: params.agentId,
  });

  const { data: demoApplication, isLoading: isLoadingDemo } = useApplication(DEMO_ID);

  const allApplications = useMemo(() => {
    if (demoApplication) {
      return [...(data?.applications || []), demoApplication];
    } else {
      return data?.applications || [];
    }
  }, [data, demoApplication]);

  const handleUpdateUrl = useCallback(
    (paramsToUpdate: Partial<QueryParams>) => {
      const paramsToUpdateList = Object.keys(paramsToUpdate);
      const filterListKeys = Object.keys(filtersList);
      const parsedParams = { ...queryParams, ...paramsToUpdate };

      // if you add any of the filterList or q -> change the page to 0
      if (intersection(filterListKeys, paramsToUpdateList).length || paramsToUpdateList.includes('q')) {
        parsedParams.page = 0;
      }

      const urlWithParams = queryString.stringify(parsedParams, {
        skipEmptyString: true,
        skipNull: true,
      });

      navigate(`?${urlWithParams}`, { replace: true });
    },
    [navigate, queryParams, filtersList],
  );

  const columns = useMemo(() => {
    return generateColumns(
      isAgentAnAdmin(agent),
      isAgentAGlobalAdmin(agent),
      isAgentAnAgencyAdmin(agent),
      isSecurityAgency(agent),
      agent.agency?.enableLeadMagnet,
      securityApplicationVersion?.id,
      // classes.overallScoreBadge,
      selectedIds,
      data?.applications,
      setSelectedIds,
      classes.checkbox,
      isAgencyAdmin,
    );
  }, [agent, securityApplicationVersion, selectedIds, setSelectedIds, data, classes, isAgencyAdmin]);

  if (agent && !agent.isEmailVerified) {
    return <VerifyEmail />;
  }

  return (
    <div className={classes.root}>
      <Helmet>
        <title>Dashboard - {agent?.agency?.name}</title>
      </Helmet>
      <Filters
        title={isSecurityAgency(agent) ? 'Assessments' : 'Applications'}
        className={classes.filters}
        onFilterChange={(paramsToUpdate) => handleUpdateUrl(paramsToUpdate)}
        currentFilters={params}
        filterList={filtersList}
        applicationIds={selectedIds}
        onBulkAction={() => {
          setSelectedIds([]);
        }}
      />
      <Table<ApplicationDTO | ApplicationWithAgentDTO, ApplicationSortBy>
        data={allApplications}
        columns={columns}
        sorter={{
          key: params.sortBy,
          order: params.sortOrder,
        }}
        loading={isLoading || isLoadingDemo}
        onChange={(pagination, sorter) => {
          if (pagination) {
            sorter
              ? handleUpdateUrl({ page: pagination.page, sortBy: sorter.key, sortOrder: sorter.order })
              : handleUpdateUrl({ page: pagination.page, sortBy: undefined, sortOrder: undefined });
          } else {
            sorter
              ? handleUpdateUrl({ sortBy: sorter.key, sortOrder: sorter.order })
              : handleUpdateUrl({ sortBy: undefined, sortOrder: undefined });
          }
        }}
        onRowClick={(application) => {
          navigate(insertIdToRoute(ROUTES.agent.application.ROOT, application.id));
        }}
        rowKey={(app) => app.id}
        pagination={{
          page: params.page,
          perPage: PER_PAGE,
          total: data?.applicationsCount || 0,
          elementName: isSecurityAgency(agent) ? 'Assessment' : 'Application',
          export: isSecurityAgency(agent) ? <ExportCsvButton agent={agent} /> : '',
        }}
      />
    </div>
  );
};
