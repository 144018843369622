import { useCurrentAgent } from '../queries/useAgent';

export const useExternalScanDisabled = () => {
  const { data } = useCurrentAgent();

  return data?.agency?.disableExternalScan || !data?.agency?.enableLeadMagnet;
};

export const useRiskAssessmentDisabled = () => {
  const { data } = useCurrentAgent();

  return data?.agency?.disableRiskAssessment;
};
