import { makeStyles } from '@material-ui/core';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import LoopIcon from '@material-ui/icons/Loop';
import { animated, useSpring } from '@react-spring/web';
import cx from 'classnames';
import { EmptyState } from 'components/EmptyState';
import { Placeholder } from 'components/Placeholder';
import { SecurityScanDTO } from 'dtos/application';
import { SecurityScanType } from 'dtos/security-finding';
import { useExternalScanFindings } from 'hooks/external-scan/useExternalScanFindings';
import uniq from 'lodash/uniq';
import { useApplicationBreachData } from 'queries/useApplications';
import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { COLORS } from 'telivy-theme';

export const useStyles = makeStyles((theme) => ({
  wrapper: {
    padding: `${theme.spacing(4)}px 0`,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    overflowX: 'auto',
  },
  innerWrapper: {
    position: 'relative',
    minWidth: 'fit-content',
    width: '100%',
  },

  dotsWrapper: {
    display: 'flex',
    gap: theme.spacing(0.5),
    justifyContent: 'space-between',
    width: '100%',
  },

  bar: {
    height: 2,
    backgroundColor: '#000',
    width: `calc(100% - ${theme.spacing(5.5)}px)`,
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    marginLeft: theme.spacing(2.25),
    marginRight: theme.spacing(2.25),
  },

  dot: {
    height: theme.spacing(2),
    width: theme.spacing(2),
    borderRadius: '50%',
    backgroundColor: '#000',
    zIndex: 2,
  },

  dotRed: {
    backgroundColor: '#D82A49',
  },

  dotGrey: {
    backgroundColor: '#5F6E7B',
  },

  text: {
    fontWeight: 600,
    textAlign: 'center',
  },

  breachText: {
    color: '#D82A49',
    fontSize: '14px !important',
  },

  yearText: {
    color: '#5F6E7B',
    fontSize: '16px !important',
  },

  linkText: {
    color: `${COLORS.BLUE_1} !important`,
    fontSize: '13px !important',
    whiteSpace: 'nowrap',
  },

  dotWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },

  emptyState: {
    padding: `${theme.spacing(2)}px 0`,
    marginTop: 'auto',
    marginBottom: 'auto',
  },

  emptyStateIcon: {
    color: COLORS.GREEN_1,
    height: 40,
    width: 40,
  },

  loadingStateIcon: {
    color: COLORS.BLUE_1,
    height: 40,
    width: 40,
    animation: '$spin 2s linear infinite',
  },

  '@keyframes spin': {
    '0%': {
      transform: 'rotate(0deg)',
    },
    '100%': {
      transform: 'rotate(360deg)',
    },
  },

  twoColumn: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    columnGap: theme.spacing(2),
    rowGap: theme.spacing(1),
  },

  noBreachesWrapper: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
}));

interface Props {
  securityScan: SecurityScanDTO;
  findingsUrl: string;
  forceShouldPullFindings?: boolean;
  applicationId: string;
  showShowMore?: boolean;
}

export const DarkWebPresenceChart = ({
  findingsUrl,
  securityScan,
  forceShouldPullFindings,
  applicationId,
  showShowMore,
}: Props) => {
  const classes = useStyles();
  const { data: breachData, isLoading: isBreachDataLoading } = useApplicationBreachData(applicationId);

  const { findingsByType, isLoadingScanEmail } = useExternalScanFindings({ securityScan, forceShouldPullFindings });
  const breaches = findingsByType?.[SecurityScanType.HACKER_CHATTER];

  if (breaches?.length > 0 && !isLoadingScanEmail && !isBreachDataLoading) {
    const endYear = new Date().getFullYear().toString();
    const yearRegex = new RegExp(`((19|20)\\d{2})`);
    const years = breaches
      .map((breach) => breach?.name?.match(yearRegex)?.[0] as string) // TODO: fix this
      .filter((year) => !!year)
      .sort((a, b) => parseInt(a) - parseInt(b));

    if (years?.length > 0) {
      const startYear = parseInt(years?.[0]) - 1;
      const uniqYears = uniq(years);
      const breachesByYear = breaches.reduce((acc, breach) => {
        const year = breach?.name?.match(yearRegex)?.[0];
        if (year) {
          acc[year] = acc[year] ? [...acc[year], breach.name] : [breach.name];
        }
        return acc;
      }, {} as { [key: string]: string[] });

      return (
        <animated.div className={classes.wrapper} id='dark-web-presence-chart'>
          <div className={classes.innerWrapper}>
            <div className={classes.bar}></div>

            <div className={classes.dotsWrapper}>
              <YearDot year={startYear} isStartYear />
              {uniqYears?.map((year, idx) => {
                const newYearDifference = () => {
                  if (uniqYears?.[idx + 1]) {
                    return parseInt(uniqYears?.[idx + 1]) - parseInt(year);
                  }
                  if (idx === uniqYears?.length - 1) {
                    return parseInt(endYear) - parseInt(year);
                  }
                  return 1;
                };

                return (
                  <BreachDot
                    key={idx}
                    year={year}
                    url={findingsUrl}
                    reverse={idx % 2 === 0}
                    numberOfFindings={breachesByYear[year]?.length}
                    nextYearDifference={newYearDifference()}
                  />
                );
              })}
              <YearDot year={endYear} />
            </div>
          </div>
        </animated.div>
      );
    }
  }

  if (breachData?.length && !isLoadingScanEmail && !isBreachDataLoading) {
    return (
      <Placeholder isActive={false} className={classes.noBreachesWrapper}>
        <EmptyState
          title='Breaches found!'
          text={
            <span>
              Not enough data to create timeline, but we found <b>{breachData?.length} breach data!</b> Explore more{' '}
              <Link to={`/agent/application/${applicationId}/security/latest/dark-web-presence`}>here</Link>.
            </span>
          }
          className={classes.emptyState}
          icon={<CheckCircleOutlineIcon className={classes.emptyStateIcon} />}
        />
      </Placeholder>
    );
  }

  if (breachData?.length) {
    return (
      <Placeholder isActive={false} className={classes.noBreachesWrapper}>
        <EmptyState
          title='Breaches found!'
          text={
            <span>
              We are gathering more data to create timeline...
              {showShowMore ? (
                <>
                  <br />
                  <b>{breachData.length} breach data found!</b> Explore more{' '}
                  <Link to={`/agent/application/${applicationId}/security/latest/dark-web-presence`}>here</Link>.
                </>
              ) : null}
            </span>
          }
          className={classes.emptyState}
          icon={<LoopIcon className={classes.loadingStateIcon} />}
        />
      </Placeholder>
    );
  }

  return (
    <Placeholder isActive={isLoadingScanEmail || isBreachDataLoading} className={classes.noBreachesWrapper}>
      <EmptyState
        title='No breaches found!'
        text='Looks like there are no breaches for your company.'
        className={classes.emptyState}
        icon={<CheckCircleOutlineIcon className={classes.emptyStateIcon} />}
      />
    </Placeholder>
  );
};

interface YearDotProps {
  year: string | number;
  isStartYear?: boolean;
}

const YearDot = ({ year, isStartYear }: YearDotProps) => {
  const classes = useStyles();

  const [props] = useSpring(
    () => ({
      from: { opacity: 0 },
      to: { opacity: 1 },
      config: { duration: 300 },
    }),
    [],
  );

  return (
    <animated.div style={{ ...props, flexGrow: isStartYear ? 1 : 0, display: 'flex', flexBasis: 0, flexShrink: 0 }}>
      <div className={classes.dotWrapper}>
        <br />
        <br />
        <div className={cx(classes.dot, classes.dotGrey)} />
        <span className={cx(classes.text, classes.yearText)}>{year}</span>
      </div>
    </animated.div>
  );
};

interface BreachDotProps {
  year: string;
  url: string;
  reverse?: boolean;
  numberOfFindings?: number;
  nextYearDifference: number;
}

const BreachDot = ({ year, url, reverse, numberOfFindings, nextYearDifference }: BreachDotProps) => {
  const classes = useStyles();

  const [props] = useSpring(
    () => ({
      from: { opacity: 0 },
      to: { opacity: 1 },
      config: { duration: 300 },
    }),
    [],
  );

  const urlComponent = useMemo(() => {
    return (
      <>
        {reverse && <br />}
        <Link to={`${url}?q=${year}`} className={cx(classes.text, classes.linkText)} style={{ fontWeight: 600 }}>
          {numberOfFindings || 0} {numberOfFindings === 1 ? 'finding' : 'findings'}
        </Link>
        {!reverse && <br />}
      </>
    );
  }, [reverse, url, year, classes.text, classes.linkText, numberOfFindings]);

  const yearComponent = useMemo(() => {
    return (
      <span className={cx(classes.text, classes.breachText)}>
        <span>{year}</span>
        <br />
        <span>Breach</span>
      </span>
    );
  }, [classes.breachText, classes.text, year]);

  const topComponent = useMemo(() => {
    if (reverse) {
      return urlComponent;
    }
    return yearComponent;
  }, [reverse, urlComponent, yearComponent]);

  const bottomComponent = useMemo(() => {
    if (reverse) {
      return yearComponent;
    }
    return urlComponent;
  }, [reverse, urlComponent, yearComponent]);

  return (
    <animated.div style={{ ...props, flexGrow: nextYearDifference, display: 'flex', flexBasis: 0, flexShrink: 0 }}>
      <div className={classes.dotWrapper}>
        <>{topComponent}</>
        <div className={cx(classes.dot, classes.dotRed)}></div>
        <>{bottomComponent}</>
      </div>
    </animated.div>
  );
};
