import { Dialog, DialogActions, DialogContent, DialogProps, DialogTitle, makeStyles, Theme } from '@material-ui/core';
import cx from 'classnames';
import { ReactComponent as CloseIcon } from 'images/close.svg';
import { isFunction } from 'lodash';
import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import { COLORS, TYPOGRAPHY } from 'telivy-theme';

interface StylesProps {
  removePaddingRight?: boolean;
  removePaddingTop?: boolean;
  padding?: number;
}

const useStyles = makeStyles<Theme, StylesProps>((theme) => ({
  root: {},
  openButton: {
    display: 'inline',
    height: '100%',
    cursor: 'pointer',
    pointerEvents: 'all',
  },
  titleBar: ({ padding }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    padding: `${theme.spacing(padding || 3)}px ${theme.spacing(padding || 3)}px 0 ${theme.spacing(padding || 3)}px`,
  }),
  title: {
    flex: '1',
    padding: 0,
    ...TYPOGRAPHY.LARGE,

    [theme.breakpoints.down('sm')]: {
      ...TYPOGRAPHY.REGULAR_BOLD,
    },
  },
  closeButtonWrapper: {
    marginLeft: theme.spacing(2),
    cursor: 'pointer',
    padding: 0,
  },
  content: ({ removePaddingRight, removePaddingTop, padding }) => ({
    color: COLORS.GREY_1,
    ...TYPOGRAPHY.SMALL_REGULAR,
    ...(padding && {
      padding: `${theme.spacing(padding || 3)}px !important`,
    }),
    ...(!removePaddingRight && {
      paddingRight: `${theme.spacing(8)}px !important`,
    }),
    paddingTop: `${theme.spacing(3)}px !important`,
    ...(removePaddingTop && {
      paddingTop: '0 !important',
    }),

    '& > p': {
      margin: 0,

      '& + p': {
        marginTop: theme.spacing(2),

        [theme.breakpoints.down('sm')]: {
          marginTop: theme.spacing(1),
        },
      },
    },

    [theme.breakpoints.down('sm')]: {
      paddingTop: `${theme.spacing(1)}px !important`,
    },
  }),
  actions: ({ padding }) => ({
    padding: `0 ${theme.spacing(padding || 3)}px ${theme.spacing(padding || 3)}px ${theme.spacing(padding || 3)}px`,
  }),
}));

interface OpenButton {
  role?: string;
  className?: string;
  onClick: () => void;
  onKeyPress?: (event: React.KeyboardEvent<HTMLButtonElement>) => void;
}

interface CommonProps extends StylesProps {
  title: string;
  children: React.ReactNode;
  autoWidth?: boolean;
  disablePortal?: boolean;
  keepMounted?: boolean;
  onOpen?: () => void;
  onClose?: () => void;
  maxWidth?: DialogProps['maxWidth'];
  fullWidth?: boolean;
}
interface ControlledModalProps extends CommonProps {
  openButton?: ReactElement<OpenButton>;
  handleClose: () => void;
  isOpen: boolean;
  actions?: React.ReactNode | null;
}
interface UncontrolledModalProps extends CommonProps {
  openButton: ReactElement<OpenButton>;
  handleClose?: () => void;
  isOpen?: boolean;
  actions?: ((handleClose: () => void) => React.ReactNode | null) | React.ReactNode | null;
}

type Props = ControlledModalProps | UncontrolledModalProps;

export const Modal = ({
  title,
  children,
  openButton,
  removePaddingRight,
  removePaddingTop,
  padding,
  autoWidth,
  actions,
  handleClose,
  onOpen,
  onClose,
  isOpen,
  disablePortal,
  keepMounted,
  maxWidth,
  fullWidth,
}: Props) => {
  const [open, setOpen] = useState(isOpen || false);
  const classes = useStyles({ removePaddingRight, removePaddingTop, padding });
  const isModalOpen = useMemo(() => isOpen || open, [isOpen, open]);

  useEffect(() => {
    setOpen(isOpen || false);
  }, [isOpen]);

  const handleOpenModal = () => {
    if (openButton) {
      setOpen(true);
    }

    onOpen && onOpen();
  };

  const handleCloseModal = () => {
    if (!openButton) {
      handleClose && handleClose();
    } else {
      setOpen(false);
    }

    onClose && onClose();
  };

  return (
    <>
      {openButton &&
        React.cloneElement(openButton, {
          role: 'presentation',
          className: cx(classes.openButton, openButton.props.className),
          onClick: handleOpenModal,
          onKeyPress: (e: React.KeyboardEvent<HTMLButtonElement>) => e.key === 'Enter' && handleOpenModal(),
        })}
      <Dialog
        maxWidth={autoWidth ? (maxWidth ? maxWidth : false) : undefined}
        fullWidth={fullWidth}
        open={isModalOpen}
        onClose={handleCloseModal}
        className={classes.root}
        disablePortal={disablePortal}
        keepMounted={keepMounted}
      >
        <div className={classes.titleBar}>
          <DialogTitle className={classes.title} disableTypography>
            {title}
          </DialogTitle>
          <DialogActions className={classes.closeButtonWrapper}>
            <div
              role='button'
              tabIndex={0}
              onClick={handleCloseModal}
              onKeyPress={(e) => e.key === 'Enter' && handleCloseModal()}
            >
              <CloseIcon />
            </div>
          </DialogActions>
        </div>
        <DialogContent className={classes.content}>{children}</DialogContent>
        {actions && (
          <DialogActions className={classes.actions}>
            {isFunction(actions) ? actions(handleCloseModal) : actions}
          </DialogActions>
        )}
      </Dialog>
    </>
  );
};
