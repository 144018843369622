import { CityData, MapChart } from 'components/charts/MapChart';
import { DeepScanGwsActivityLogin } from 'dtos/deep-scan';
import React, { useMemo } from 'react';

export interface ParsedLocation extends CityData {
  successfulLogins: number;
  failedLogins: number;
}

export const useGwsLoginMapChart = (data?: DeepScanGwsActivityLogin[]) => {
  const loginByLocation = useMemo(() => {
    const res: Record<string, DeepScanGwsActivityLogin[]> = {};
    if (data) {
      for (const al of data) {
        if (!al.location) continue;

        const location = [al.location.city, al.location.state, al.location.countryOrRegion].join(', ');
        res[location] = res[location] || [];
        res[location].push(al);
      }
    }
    return res;
  }, [data]);

  const regions: ParsedLocation[] = useMemo(() => {
    const res: ParsedLocation[] = [];
    for (const location of Object.keys(loginByLocation)) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const [city, state, country] = location.split(', ');
      const locData = loginByLocation[location];
      if (!locData[0].location) continue;

      const latitude = locData[0].location.geoCoordinates.latitude;
      const longitude = locData[0].location.geoCoordinates.longitude;
      const failedLogins = locData.filter((l) => l.events.filter((e) => e.name === 'login_failure').length > 0).length;
      const successfulLogins = locData.filter(
        (l) =>
          l.events.filter((e) => e.name === 'login_success').length > 0 &&
          l.events.filter((e) => e.name === 'login_failure').length === 0,
      ).length;

      res.push({
        city: state,
        lat: latitude,
        lng: longitude,
        value: successfulLogins + failedLogins,
        successfulLogins,
        failedLogins,
        fill: '#516AE6',
      });
    }

    // sum up all the cities in the same state and point lat long to average
    const states: Record<string, ParsedLocation & { count: number; latSum: number; lngSum: number }> = {};
    for (const location of res) {
      const state = location.city;

      if (states[state]) {
        states[state].successfulLogins += location.successfulLogins;
        states[state].failedLogins += location.failedLogins;
        states[state].value += location.value;
        states[state].count += 1;
        states[state].latSum += location.lat;
        states[state].lngSum += location.lng;
      } else {
        states[state] = {
          ...location,
          count: 1,
          latSum: location.lat,
          lngSum: location.lng,
        };
      }
    }

    return Object.keys(states).map((state) => {
      const loc = states[state];
      const failureRate = (loc.failedLogins * 100) / loc.value;
      return {
        ...loc,
        fill: loc.value == 0 ? '#000000' : failureRate > 20 ? '#FF0000' : failureRate > 5 ? '#FFA500' : '#29b51c',
        lat: loc.latSum / loc.count,
        lng: loc.lngSum / loc.count,
      };
      // return {
      //   ...loc,
      //   fill: loc.failedLogins > loc.successfulLogins ? '#FF0000' : '#516AE6',
      //   lat: loc.latSum / loc.count,
      //   lng: loc.lngSum / loc.count,
      // };
    });
  }, [loginByLocation]);

  const chart = useMemo(() => {
    if (data?.length === 0) {
      return null;
    }

    return (
      <MapChart<ParsedLocation>
        cities={regions}
        id='login-map'
        width='100%'
        height={640}
        tooltipContent={(c) => (
          <span>
            <b>{c.city}</b>
            <br />
            Successful: <b>{c.successfulLogins}</b>
            <br />
            Failed: <b>{c.failedLogins}</b>
          </span>
        )}
      />
    );
  }, [data?.length, regions]);

  return {
    chart,
    regions,
    loginByLocation,
  };
};
