import { makeStyles } from '@material-ui/core/styles';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { AxiosError } from 'axios';
import cx from 'classnames';
import { ApplicationStatusLabel } from 'components/ApplicationStatusLabel';
import { ScanningStatusLabel } from 'components/ApplicationStatusLabel/ScanningStatusLabel';
import { EffectiveDate } from 'components/EffectiveDate';
import { EmptyState } from 'components/EmptyState';
import { LoadingContainer } from 'components/LoadingContainer';
import { NotFoundContainer } from 'components/NotFoundContainer';
import { VerifyEmail } from 'components/VerifyEmail';
import { AgentDTO, AgentRole } from 'dtos/agent';
import { ApplicationStatus, ApplicationWithAgentDTO, MonitoringFrequency, SecurityScanStatus } from 'dtos/application';
import { useExternalScanDisabled } from 'hooks/useAccountDisabled';
import { useRiskMonitoringEnabled } from 'hooks/useChartsEnabled';
import moment from 'moment';
import { useCurrentAgent } from 'queries/useAgent';
import {
  useApplication,
  useApplicationSecurityScan,
  useLatestApplication,
  useLatestSecurityApplication,
} from 'queries/useApplications';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import { UseQueryOptions, UseQueryResult } from 'react-query';
import { Link, Navigate, Route, Routes, useNavigate, useParams } from 'react-router-dom';
import { createSecurityRoute, DEMO_ID, generateRefetchIntervalMs, insertIdToRoute, ROUTES } from 'telivy-constants';
import { mapScanningStatus } from 'telivy-maps';
import { isAgentAnAdmin, isScanApplication, isSecurityAgency, isSecurityApplication } from 'telivy-selectors';
import { COLORS, TYPOGRAPHY } from 'telivy-theme';
import { OverallScoreBadge } from 'templates/SecurityAssessments/components/OverallScoreBadge';
import { SecurityOverview } from 'templates/SecurityAssessments/views/SecurityOverview';
import { ChangeStatusModal } from 'views/agent/views/application-details/ChangeStatusModal';
import { ConvertButton } from 'views/agent/views/application-details/views/security/ConvertButton';
import { ConvertMonitoringButton } from 'views/agent/views/application-details/views/security/ConvertMonitoringButton';
import { EditAssessmentButton } from 'views/agent/views/application-details/views/security/EditAssessmentButton';
import { MarkArchivedButton } from 'views/agent/views/application-details/views/security/MarkArchivedButton';
import { RequestForReportButton } from 'views/agent/views/application-details/views/security/RequestForReportButton';
import { ClientStatusLabel } from 'views/agent/views/crm/ClientStatusLabel';

import { Menu } from './Menu';
import { ApplicationAlertsView } from './views/alerts';
import { ApplicationAssetsView } from './views/assets';
import { ApplicationCompanyView } from './views/company';
import { ApplicationConfigurationView } from './views/configuration';
import { ApplicationDocumentsView } from './views/documents';
import { ApplicationFormView } from './views/form';
import { ApplicationHistoryView } from './views/history';
import { ApplicationInventoryView } from './views/inventory';
import { ApplicationMonitoringView } from './views/monitoring';
import { ApplicationPoliciesView } from './views/policies';
import { ApplicationProposalView } from './views/proposals';
import { ApplicationQuotesView } from './views/quotes';
import { ApplicationRemediationView } from './views/remediation';
import { ApplicationSecurityView } from './views/security';
import { DeleteButton } from './views/security/DeleteButton';
import { ApplicationServicesView } from './views/services';

const {
  HISTORY,
  FORM,
  PROPOSAL,
  REPORTS,
  SERVICES,
  QUOTES,
  company: companyRoutes,
  setup: setupRoutes,
  DOCUMENTS,
  POLICIES,
  MONITORING,
  alerts,
  remediation,
  overview,
  security,
  targets,
  inventory,
} = ROUTES.agent.application;

export const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
  },

  // Header elements
  header: {
    display: 'flex',
    flexDirection: 'column',
    padding: `0 ${theme.spacing(4)}px`,
  },

  details: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(),
  },
  title: {
    ...TYPOGRAPHY.LARGE,
    color: COLORS.GREY_1,
    margin: 0,
  },
  subtitle: {
    ...TYPOGRAPHY.SMALL_REGULAR,
    color: COLORS.GREY_2,
    margin: 0,
  },
  effectiveDate: {
    ...TYPOGRAPHY.EXTRA_SMALL_REGULAR,
    color: COLORS.GREY_2,
    marginBottom: 0,
  },

  actionsMainContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: theme.spacing(1.5),
  },
  infoWrapper: {
    display: 'flex',
    gap: 16,
    width: '100%',
    flex: 1,
  },
  actionsContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1.5),
    whiteSpace: 'nowrap',
  },
  status: {
    height: 'fit-content',
  },
  actionButton: {
    border: 'none',
    // backgroundColor: COLORS.GREY_6,
    color: COLORS.GREY_3,
    padding: `2px`,
    minWidth: 0,

    '& svg': {
      fontSize: theme.spacing(2.5),
    },

    '&:hover': {
      color: COLORS.GREY_1,
    },
  },

  menu: {
    // marginBottom: theme.spacing(3),
    marginRight: theme.spacing(2),
  },
  fullWidth: {
    width: '100%',
  },

  // Content
  main: {
    marginTop: theme.spacing(1),
    padding: `0 ${theme.spacing(4)}px`,
    display: 'flex',
    flexDirection: 'row',
  },
  center: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  mainContent: {
    flex: 1,
    padding: `${theme.spacing(2)}px 0 ${theme.spacing(3)}px ${theme.spacing(2)}px`,
  },

  convertButton: {
    alignSelf: 'end',
    color: 'white',
    background: 'linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab)',
    backgroundSize: '600% 600%',
    animation: '$gradient 5s ease infinite',
  },

  '@keyframes gradient': {
    '0%': {
      backgroundPosition: '0% 50%',
    },
    '50%': {
      backgroundPosition: '100% 50%',
    },
    '100%': {
      backgroundPosition: '0% 50%',
    },
  },
}));

interface Props {
  agent: AgentDTO;
}

export const ApplicationDetailsView = ({ agent }: Props) => {
  const classes = useStyles();
  const navigate = useNavigate();

  const monitoringEnabled = useRiskMonitoringEnabled();
  const [errorMessage, setErrorMessage] = useState('');

  const { data: currentAgent, isLoading: isLoadingAgent } = useCurrentAgent();
  const externalScanDisabled = useExternalScanDisabled();

  const isAdmin = isAgentAnAdmin(agent);

  const [securityOverallScore, setSecurityOverallScore] = useState<number | null>(null);
  const { id } = useParams();
  const { data: dataApplicationById, isLoading: isLoadingApplicationById } = useApplication(id || '', {
    enabled: id !== 'latest',
  });

  // get latest application based on flow type, just for safety/future use
  const fnHook: (options?: UseQueryOptions<ApplicationWithAgentDTO>) => UseQueryResult<ApplicationWithAgentDTO> =
    isSecurityAgency(agent) ? useLatestApplication : useLatestSecurityApplication;

  const { data: dataLatestApplication, isLoading: isLoadingLatestApplication } = fnHook({
    enabled: id === 'latest',
    retry: 1,
    onError: () => {
      // if latest application is not found, redirect applications list
      if (id === 'latest') {
        navigate(ROUTES.agent.DASHBOARD);
      }
    },
  });
  const isLoading = isLoadingAgent || isLoadingApplicationById ? isLoadingApplicationById : isLoadingLatestApplication;
  const data = dataApplicationById ? dataApplicationById : dataLatestApplication;
  const company = useMemo(() => data?.company, [data]);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const applicationStatusButtonRef = useRef<HTMLButtonElement>(null);

  const onViewQuestionnaireClick = useCallback(
    () => id && navigate(insertIdToRoute(ROUTES.agent.application.FORM, id)),
    [id, navigate],
  );

  const nextScanDate = useMemo(() => {
    switch (data?.monitoringFrequency) {
      case MonitoringFrequency.WEEKLY:
        return moment().add(1, 'weeks').startOf('isoWeek').format('Do MMMM YYYY');
      case MonitoringFrequency.MONTHLY:
        return moment().add(1, 'months').startOf('month').format('Do MMMM YYYY');
      case MonitoringFrequency.QUARTERLY:
        return moment().add(1, 'quarters').startOf('quarter').format('Do MMMM YYYY');
      default:
        return null;
    }
  }, [data?.monitoringFrequency]);

  const isNotSubmitted = useMemo(() => {
    return (
      !data?.applicationStatus ||
      [ApplicationStatus.NOT_STARTED, ApplicationStatus.IN_PROGRESS, ApplicationStatus.IN_REVIEW].includes(
        data?.applicationStatus,
      )
    );
  }, [data]);

  const { data: securityScan, isLoading: isLoadingSecurityScan } = useApplicationSecurityScan(id || '', '', {
    refetchInterval: (data) =>
      data && data?.status !== SecurityScanStatus.COMPLETED ? generateRefetchIntervalMs() : false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
    onError: (e) => {
      const response = (e as AxiosError<{ message: string }>).response;
      if (response) {
        setErrorMessage(response.data.message);
      }
    },
  });

  const rootRoute: string = React.useMemo(() => {
    if (isScanApplication(data) && id) {
      if (
        [
          ApplicationStatus.NOT_STARTED,
          ApplicationStatus.CONFIGURATION_PENDING,
          ApplicationStatus.CONFIGURATION_DONE,
          ApplicationStatus.DATA_PENDING,
        ].includes(data?.applicationStatus || ApplicationStatus.NOT_STARTED)
      ) {
        return insertIdToRoute(setupRoutes.ROOT, id);
      } else {
        return createSecurityRoute(overview.ROOT, id, 'latest');
      }
    } else if (isSecurityApplication(data) && id) {
      return createSecurityRoute(overview.ROOT, id, 'latest');
    } else {
      return insertIdToRoute(FORM, id || '');
    }
  }, [data, id]);

  const canSeeContent = useMemo(() => {
    const { role, agencyId, id, agency } = currentAgent || {};

    if (data?.id === DEMO_ID) return true;
    if (role === AgentRole.TELIVY_ADMIN) return true;

    if (role === AgentRole.AGENCY_ADMIN && agency?.isMasterAgency) {
      return data?.agent?.agency?.masterAgencyId === agencyId || data?.agent.agencyId === agencyId;
    }

    if (role === AgentRole.AGENCY_ADMIN) {
      return data?.agent.agencyId === agencyId;
    }

    return data?.agent.id === id;
  }, [currentAgent, data?.agent.agencyId, data?.agent?.agency?.masterAgencyId, data?.agent.id, data?.id]);

  if (currentAgent && !currentAgent.isEmailVerified) {
    return <VerifyEmail />;
  }

  if (data && currentAgent && !canSeeContent) {
    return (
      <EmptyState title='Forbidden' text={<>You do not have access to view this page.</>} icon={<LockOutlinedIcon />} />
    );
  }

  return (
    <div className={classes.root}>
      <Helmet>
        <title>
          {`${data ? (data.deepScanId ? 'Risk' : 'External') : ''} Assessment - ${
            data?.applicationResponse?.organization_name
          }`}
        </title>
      </Helmet>

      <div className={classes.header}>
        {data && !isLoading && (
          <div className={classes.details}>
            <div className={classes.actionsMainContainer}>
              <div className={classes.infoWrapper}>
                {isSecurityApplication(data) && !isScanApplication(data) && (
                  <OverallScoreBadge variant='big' securityOverallScore={securityOverallScore} />
                )}
                <div className={classes.fullWidth}>
                  <div className={classes.actionsContainer}>
                    <h1 className={classes.title}>{data.applicationResponse.organization_name}</h1>
                    <EditAssessmentButton
                      application={data}
                      className={classes.actionButton}
                      locked={externalScanDisabled}
                    />
                    <div className={classes.actionsContainer}>
                      {company?.clientStatus && <ClientStatusLabel clientStatus={company.clientStatus} fullWidth />}
                      {!data.deepScanId && (
                        <ScanningStatusLabel
                          status={mapScanningStatus(data.securityScan?.status)}
                          fullWidth
                          isAdmin={isAdmin}
                        />
                      )}
                      {(data.deepScanId || isAdmin) && (
                        <ApplicationStatusLabel
                          className={classes.status}
                          status={data.applicationStatus}
                          onClick={isAdmin ? () => setIsModalOpen(true) : undefined}
                          buttonRef={applicationStatusButtonRef}
                          isAdmin={isAdmin}
                        />
                      )}
                      <ChangeStatusModal
                        isOpen={isModalOpen}
                        application={data}
                        onClose={() => setIsModalOpen(false)}
                        buttonRef={applicationStatusButtonRef}
                        isAdmin={isAdmin}
                      />
                    </div>
                  </div>
                  {isSecurityApplication(data) && data.applicationResponse?.domain_prim !== 'nodomain.local' && (
                    <h2 className={classes.subtitle}>{data.applicationResponse?.domain_prim}</h2>
                  )}
                </div>
              </div>
              {isSecurityApplication(data) && !isScanApplication(data) && (
                <div className='flex gap-2'>
                  {data.applicationStatus == ApplicationStatus.ARCHIVED && <DeleteButton application={data} />}
                  <MarkArchivedButton
                    applicationId={data.id}
                    isArchived={data.applicationStatus == ApplicationStatus.ARCHIVED}
                  />
                  <ConvertButton applicationId={data.id} conversionPermitted={true} />
                </div>
              )}
              {isScanApplication(data) && (
                <div className='flex gap-2'>
                  {data.isLightScan && (
                    <RequestForReportButton
                      applicationId={data.id}
                      isRequestedForReport={data.applicationStatus == ApplicationStatus.REPORT_REQUESTED}
                      requestPermitted={true}
                    />
                  )}
                  {data.applicationStatus == ApplicationStatus.ARCHIVED && <DeleteButton application={data} />}
                  <MarkArchivedButton
                    applicationId={data.id}
                    isArchived={data.applicationStatus == ApplicationStatus.ARCHIVED}
                  />
                  {!data.monitoringEnabled && monitoringEnabled && (
                    <ConvertMonitoringButton
                      applicationId={data.id}
                      monitoringFrequency={data.monitoringFrequency}
                      conversionPermitted={true}
                      className={classes.convertButton}
                    />
                  )}
                  {data.monitoringEnabled && data.monitoringFrequency && (
                    <Link
                      to={insertIdToRoute(ROUTES.agent.application.MONITORING, id || '')}
                      className='no-underline flex gap-2'
                    >
                      <div className={cx('px-6 py-2 text-sm rounded-lg pointer-events-none', classes.convertButton)}>
                        <b>Next scan scheduled for:</b> {nextScanDate}
                      </div>
                    </Link>
                  )}
                </div>
              )}
            </div>
            {!isSecurityApplication(data) && (
              <p className={classes.effectiveDate}>
                Assessment Type: {data.insuranceLine} &middot; Target Effective: <EffectiveDate application={data} />
              </p>
            )}
          </div>
        )}
      </div>

      <main className={cx(classes.main, isLoading && classes.center)}>
        {data && <Menu application={data} className={classes.menu} agent={agent} />}
        <div className={classes.mainContent}>
          {errorMessage && <NotFoundContainer message={errorMessage} />}

          {data && !isLoading && !isLoadingSecurityScan && id && securityScan ? (
            <Routes>
              <Route index element={<Navigate replace to={rootRoute} />} />
              <Route
                path={overview.ROOT}
                element={
                  <SecurityOverview
                    onViewQuestionnaireClick={onViewQuestionnaireClick}
                    securityScan={securityScan}
                    applicationId={id}
                    isNotSubmitted={isNotSubmitted}
                    handleOpenExternalScan={() => navigate(createSecurityRoute(security.EXTERNAL_SCAN, id, 'latest'))}
                    sourceRoute={ROUTES.agent.application}
                    securityScanId={'latest'}
                    application={data}
                  />
                }
              />
              <Route path={FORM} element={<ApplicationFormView application={data} />} />
              <Route
                path={security.PARENT}
                element={
                  <ApplicationSecurityView application={data} setSecurityOverallScore={setSecurityOverallScore} />
                }
              />
              <Route path={HISTORY} element={<ApplicationHistoryView application={data} />} />
              <Route path={MONITORING} element={<ApplicationMonitoringView application={data} isAdmin={isAdmin} />} />
              <Route path={QUOTES} element={<ApplicationQuotesView application={data} agent={agent} />} />
              <Route path={PROPOSAL} element={<ApplicationProposalView application={data} />} />
              <Route path={DOCUMENTS} element={<ApplicationDocumentsView application={data} agent={agent} />} />
              <Route path={REPORTS} element={<ApplicationDocumentsView application={data} agent={agent} reports />} />
              <Route path={SERVICES} element={<ApplicationServicesView application={data} />} />
              <Route
                path={alerts.PARENT}
                element={
                  <ApplicationAlertsView application={data} sourceRoute={ROUTES.agent.application} agent={agent} />
                }
              />
              <Route
                path={remediation.PARENT}
                element={<ApplicationRemediationView application={data} sourceRoute={ROUTES.agent.application} />}
              />
              <Route
                path={companyRoutes.PARENT}
                element={<ApplicationCompanyView application={data} sourceRoute={ROUTES.agent.application} />}
              />
              <Route path={POLICIES} element={<ApplicationPoliciesView application={data} />} />
              <Route
                path={setupRoutes.PARENT}
                element={
                  <ApplicationConfigurationView
                    application={data}
                    company={company}
                    setSecurityOverallScore={setSecurityOverallScore}
                    sourceRoute={ROUTES.agent.application}
                  />
                }
              />
              <Route
                path={targets.PARENT}
                element={<ApplicationAssetsView application={data} sourceRoute={ROUTES.agent.application} />}
              />
              <Route
                path={inventory.PARENT}
                element={<ApplicationInventoryView application={data} sourceRoute={ROUTES.agent.application} />}
              />
            </Routes>
          ) : (
            <LoadingContainer />
          )}
        </div>
      </main>
    </div>
  );
};
