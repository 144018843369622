import { Button, IconButton } from '@material-ui/core';
import { FileCopy as CopyIcon, Refresh as RefreshIcon, VpnKey as KeyIcon } from '@material-ui/icons';
import cx from 'classnames';
import { AgentDTO } from 'dtos/agent';
import { useToasters } from 'hooks/useToasters';
import { useRegenerateAgencyApiKey } from 'queries/useAgency';
import React, { useMemo } from 'react';

interface Props {
  className?: string;
  agent: AgentDTO;
}

export const ManageApiKey = ({ className, agent }: Props) => {
  const { showToaster } = useToasters();
  const { mutate, isLoading } = useRegenerateAgencyApiKey(agent.agency?.id || '', {
    onSuccess: () => {
      showToaster({ message: `API key ${agent.agency?.apiKey ? 'regenerated' : 'generated'} successfully` });
    },
  });

  const handleCopy = async () => {
    if (agent.agency?.apiKey) {
      await navigator.clipboard.writeText(agent.agency.apiKey);
      showToaster({ message: 'API key copied to clipboard' });
    }
  };

  const buttonCopy = useMemo(() => {
    if (isLoading) {
      return agent.agency?.apiKey ? 'Regenerating...' : 'Generating...';
    }

    return agent.agency?.apiKey ? 'Regenerate' : 'Generate';
  }, [isLoading, agent.agency?.apiKey]);

  return (
    <div className={cx('p-6', className)}>
      <div className='flex items-center gap-2 mb-4'>
        <KeyIcon color='primary' />
        <h3 className='m-0'>API Key</h3>
      </div>

      <p className='text-gray-600 mb-6'>
        Key is used to authenticate your agency with Telivy API. Regenerating the API Key will invalidate the current
        key. <b>Do not share this key with anyone.</b>
      </p>

      <div className='mb-6'>
        {agent.agency?.apiKey ? (
          <div className='relative bg-gray-100 py-2 pl-4 flex justify-between items-center rounded-xl font-mono break-all'>
            {agent.agency.apiKey}
            <IconButton className='!p-2' size='small' onClick={handleCopy}>
              <CopyIcon fontSize='small' />
            </IconButton>
          </div>
        ) : null}
      </div>

      <Button
        variant='contained'
        color='primary'
        onClick={() => mutate()}
        disabled={isLoading}
        className='flex items-center gap-2'
      >
        <RefreshIcon className='mr-2' />
        {buttonCopy}
      </Button>
    </div>
  );
};
