import { Button, Dialog, DialogActions, DialogContent, Typography } from '@material-ui/core';
import TrashIcon from '@material-ui/icons/Delete';
import { ApplicationDTO } from 'dtos/application';
import { useToasters } from 'hooks/useToasters';
import { useDeleteApplication } from 'queries/useApplications';
import React, { useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
import { ROUTES } from 'telivy-constants';

interface Props {
  application: ApplicationDTO;
  className?: string;
}

export const DeleteButton: React.FC<Props> = ({ application, className }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { showToaster } = useToasters();
  const navigate = useNavigate();

  const text = useMemo(() => {
    if (application.scheduledForDeletionAt) {
      return 'Assessment is scheduled for deletion';
    }

    return application.deepScanId ? 'Schedule for permanent deletion' : 'Permanently Delete';
  }, [application.deepScanId, application.scheduledForDeletionAt]);

  const { mutate, isLoading } = useDeleteApplication(application.id, {
    onSuccess: () => {
      navigate(`/${ROUTES.agent.DASHBOARD}`);
      showToaster(`Successfully deleted assessment and all associated data`);
      setIsModalOpen(false);
    },
  });

  const handleModalOpen = () => {
    setIsModalOpen(true);
  };

  return (
    <>
      <Button
        className={className}
        onClick={handleModalOpen}
        variant='outlined'
        color='default'
        disabled={Boolean(application.scheduledForDeletionAt)}
        startIcon={<TrashIcon />}
      >
        {text}
      </Button>
      <Dialog fullWidth={true} open={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <DialogContent>
          <Typography variant='h3' className='!mb-4'>
            Are you sure?
          </Typography>
          <Typography variant='body1'>
            Deleting this assessment will also delete all associated data. This action cannot be undone.
            {application.deepScanId
              ? ' Due to the large amount of data, deletion process will be scheduled and happen in background. You can expect all data to be deleted within 1-3 business days.'
              : ''}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsModalOpen(false)}>Cancel</Button>
          <Button variant='contained' color='secondary' disabled={isLoading} onClick={() => mutate()}>
            {isLoading ? 'Deleting...' : 'Delete'}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
