/* eslint-disable indent */
import { Button, CircularProgress, IconButton, makeStyles, useTheme } from '@material-ui/core';
import InfoRoundedIcon from '@material-ui/icons/InfoRounded';
import LaunchRoundedIcon from '@material-ui/icons/LaunchRounded';
import cx from 'classnames';
import { Badge, BadgeVariant } from 'components/Badge';
import { LinkButton } from 'components/LinkButton';
import { Modal } from 'components/Modal';
import { formatNumberWithCommas, Money } from 'components/Money';
import { Placeholder } from 'components/Placeholder';
import { Column, Table } from 'components/Table';
import {
  ApplicationStatus,
  ApplicationWithAgentDTO,
  Finding,
  SecurityGrade,
  SecurityScanDTO,
  SecurityScanStatus,
} from 'dtos/application';
import { ApplicationsRiskReport, PiiCountry, PiiCurrencyConversion } from 'dtos/deep-scan';
import { SecurityFindingSlug, SecurityScanType } from 'dtos/security-finding';
import { useExternalScanFindings } from 'hooks/external-scan/useExternalScanFindings';
import { useExternalScanGrades } from 'hooks/external-scan/useExternalScanGrades';
import { sortBy, uniq } from 'lodash';
import moment from 'moment';
import { useCurrentAgent } from 'queries/useAgent';
import {
  useDeepScan,
  useDeepScanBrowserPasswords,
  useDeepScanParsedReports,
  useDeepScanReportData,
  useDeepScanTargets,
} from 'queries/useDeepScan';
import React, { useMemo, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { createSecurityRoute, insertIdToRoute } from 'telivy-constants';
import { isAgentAnAdmin, isAgentAnAnyAdmin, isScanApplication } from 'telivy-selectors';
import { COLORS, TYPOGRAPHY } from 'telivy-theme';
import { parseScoreToGrade } from 'templates/SecurityAssessments';

import { SourceRoute } from '..';
import { ActionItem, ActionItems } from '../components/ActionItems';
import { mapGradeToColor, ScoreSecurityCard } from '../components/common/ScoreSecurityCard';
import { ConfigurePiiCostsModal } from '../components/ConfigurePiiCostsModal';
import { DarkWebPresenceChart } from '../components/DarkWebPresenceChart';
import { DomainInfo } from '../components/DomainInfo';
import { ExecutiveSummary } from '../components/ExecutiveSummary';
import { ExternalScanRadarChart } from '../components/ExternalScanRadarChart';
import { FinancialRiskCalculator } from '../components/FinancialRiskCalculator';
import { GaugeChart, mapSecurityScoreLabel, MarkerType } from '../components/GaugeChart';
import { InsurabilityScoreCircularProgress } from '../components/insurability/InsurabilityScoreCircularProgress';
import { InsurabilityScoreComponentsTable } from '../components/insurability/InsurabilityScoreComponentsTable';
import { RescanButton } from '../components/RescanButton';
import { RiskAssessmentRadarChart } from '../components/RiskAssessmentRadarChart';
import { SecurityCard } from '../components/SecurityCard';
// import { TechstackTools } from '../components/techstack/TechstackTools';
import { VerticalStepper } from '../components/VerticalStepper';
import { SecurityExternalScan } from './SecurityExternalScan';

export const useStyles = makeStyles((theme) => ({
  root: {
    ...TYPOGRAPHY.SMALL_REGULAR,
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
  },

  singleColumn: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    gap: theme.spacing(2),

    ['@media print']: {
      display: 'block',
    },
  },
  doubleColumn: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gap: theme.spacing(2),

    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: '1fr',
    },

    ['@media print']: {
      display: 'block',
    },
  },

  tripleColumn: {
    display: 'grid',
    gridTemplateColumns: '1fr 2fr',
    gap: theme.spacing(2),

    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: '1fr',
    },

    ['@media print']: {
      display: 'block',
    },
  },

  quadrubleColumn: {
    display: 'grid',
    gridTemplateColumns: '3fr 3fr 3fr 3fr 3fr 3fr',
    gap: theme.spacing(2),

    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: 'repeat(2, 1fr)',
    },

    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr',
    },

    ['@media print']: {
      display: 'block',
    },
  },

  pentaColumn: {
    display: 'grid',
    gridTemplateColumns: '3fr 4fr 4fr 5fr 4fr',
    gap: theme.spacing(2),

    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: 'repeat(3, 1fr)',
    },

    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr',
    },

    ['@media print']: {
      display: 'block',
    },
  },

  multiColumn: {
    marginBottom: theme.spacing(2),
    display: 'flex',
    gap: theme.spacing(2),
  },

  column: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1.5),
  },

  gaugeColumn: {
    display: 'grid',
    gap: theme.spacing(2),
    gridTemplateColumns: 'repeat(2, 1fr)',

    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: '1fr',
    },

    ['@media print']: {
      display: 'block',
    },
  },
  gaugeColumnOneItem: {
    gridTemplateColumns: '1fr',
  },

  aiColumn: {
    display: 'grid',
    gap: theme.spacing(2),
    gridTemplateColumns: 'repeat(3, 1fr)',

    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: '1fr',
    },

    ['@media print']: {
      display: 'block',
    },
  },
  aiColumnOneItem: {
    gridTemplateColumns: '1fr',
  },

  securityCard: {
    flex: 1,
  },

  securityScoreTitle: {
    ...TYPOGRAPHY.SMALL_BOLD,
    // color: COLORS.BLUE_1,
    textTransform: 'uppercase',
  },
  infoIcon: {
    color: `${COLORS.GREY_4} !important`,
    cursor: 'pointer',
    marginLeft: theme.spacing(0.5),
    marginBottom: theme.spacing(-0.25),
    transition: 'color 0.2s ease-in-out',
    fontSize: 16,

    '&:hover': {
      color: `${COLORS.BLUE_1} !important`,
    },
  },

  centerButton: {
    margin: '0 auto',

    '& span': {
      ...TYPOGRAPHY.SMALL_BOLD,
    },
  },

  greyText: {
    color: COLORS.GREY_2,

    '& b': {
      color: COLORS.GREY_1,
    },
  },
  redText: {
    color: COLORS.RED_1,
  },
  centerText: {
    textAlign: 'center',
  },

  stepperItem: {
    ...TYPOGRAPHY.SMALL_BOLD,
  },

  modalButton: {
    ...TYPOGRAPHY.EXTRA_SMALL_MEDIUM,
    color: COLORS.BLUE_1,
    textDecoration: 'underline',
    height: 'auto',
    display: 'flex',
    alignItems: 'center',
  },

  modalButtonIcon: {
    fontSize: 14,
    marginRight: theme.spacing(0.25),
  },

  editButton: {
    padding: theme.spacing(0.5),
    color: COLORS.GREY_4,
  },
  redLinkButton: {
    ...TYPOGRAPHY.SMALL_REGULAR,
    color: `${COLORS.RED_1} !important`,
  },
  blueLinkButton: {
    ...TYPOGRAPHY.SMALL_MEDIUM,
    color: `${COLORS.BLUE_1} !important`,
  },

  gaugeSecurityCardContent: {
    position: 'relative',
  },
  gaugeWrapper: {
    width: '100%',
    height: GAUGE_GRAPH_HEIGHT,
    position: 'absolute',
    top: theme.spacing(2),
    left: 0,
  },
  gaugeSecurityCardContentInnerContent: {
    marginTop: GAUGE_GRAPH_HEIGHT,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: theme.spacing(),
  },

  radarSecurityCardContent: {
    position: 'relative',
    minHeight: RADAR_GRAPH_HEIGHT + 35,
  },
  radarWrapper: {
    width: '100%',
    height: RADAR_GRAPH_HEIGHT,
    position: 'absolute',
    top: theme.spacing(2),
    left: 0,
  },
  radarSecurityCardContentInnerContent: {
    marginTop: RADAR_GRAPH_HEIGHT,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    gap: theme.spacing(),
  },

  darkWebWrapper: {
    // [theme.breakpoints.up('lg')]: {
    //   maxWidth: 'calc(50vw - 80px)',
    // },
  },

  insurabilityMarketsWrapper: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gap: theme.spacing(2),
  },

  lastScanned: {
    ...TYPOGRAPHY.SMALL_MEDIUM,
    // color: COLORS.GREY_2,
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    marginTop: theme.spacing(2),
    fontSize: '10px !important',
    color: 'grey',
  },
  scanning: {
    marginRight: theme.spacing(1),
  },
  viewLatest: {
    marginLeft: theme.spacing(1),
  },
  noBorder: {
    padding: 0,
    minWidth: 0,
    border: 'none',
    '&:hover': {
      background: 'none',
      border: 'none',
    },
  },
  table: {
    width: '100%',
    backgroundColor: 'white',
  },
  noBreachesWrapper: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
}));

const GAUGE_GRAPH_HEIGHT = 200;
const RADAR_GRAPH_HEIGHT = 300;

export const getScoreValueForGrade = (grade?: SecurityGrade) => {
  switch (grade) {
    case SecurityGrade.A:
      return 95;
    case SecurityGrade.B:
      return 85;
    case SecurityGrade.C:
      return 75;
    case SecurityGrade.D:
      return 65;
    case SecurityGrade.E:
      return 55;
    default:
      return 50;
  }
};

interface Props {
  securityScan: SecurityScanDTO;
  isNotSubmitted?: boolean;
  onViewQuestionnaireClick: () => void;
  applicationId: string;
  handleOpenExternalScan: () => void;
  sourceRoute: SourceRoute;
  securityScanId: string;
  application: ApplicationWithAgentDTO;
}

const scrollToFinancialCalculator = () => {
  const section = document.querySelector('#financial-exposure');
  section?.scrollIntoView({ behavior: 'smooth', block: 'start' });
};

const AI_PER_PAGE = 3;

export const SecurityOverview = ({
  securityScan,
  onViewQuestionnaireClick,
  applicationId,
  handleOpenExternalScan,
  sourceRoute,
  isNotSubmitted,
  securityScanId,
  application,
}: Props) => {
  const classes = useStyles();
  const theme = useTheme();
  const navigate = useNavigate();
  const { data: user } = useCurrentAgent();
  const isExternalScan = !application.isDeepScan && !application.isLightScan;
  const isAdmin = isAgentAnAdmin(user);

  const [isSavingFinancialCalculator, setIsSavingFinancialCalculator] = useState(false);

  const dataBreachCost = securityScan.securityStats.dataBreachItems.reduce((prev, curr) => prev + curr.cost, 0);

  const { data: deepScan, isLoading: isLoadingDeepScan } = useDeepScan(application.deepScanId || '', {
    enabled: Boolean(application.deepScanId),
  });

  const { data: deepScanTargets, isLoading: isTargetsLoading } = useDeepScanTargets(application.deepScanId || '', {
    enabled: Boolean(application.deepScanId),
  });

  const { data: report, isLoading: isLoadingReport } = useDeepScanParsedReports(application.deepScanId || '', {
    enabled: Boolean(application.deepScanId),
  });

  const { data: browserPasswords, isLoading: isLoadingBrowserPassword } = useDeepScanBrowserPasswords(
    application.deepScanId || '',
    { enabled: Boolean(application.deepScanId) },
  );

  const defaultPIICurrency = deepScan?.piiConfig?.currency || user?.agency?.piiConfig.currency || ('$' as PiiCountry);
  const defaultPIICountry = deepScan?.piiConfig?.country || user?.agency?.piiConfig.country || 'US';

  const dataBreachItems = securityScan.securityStats.dataBreachItems.map((item) => ({
    title: (
      <span className={classes.stepperItem}>
        {defaultPIICurrency}
        {formatNumberWithCommas(
          Math.trunc(item.cost * PiiCurrencyConversion[defaultPIICountry]),
          deepScan?.piiConfig?.country,
        )}
      </span>
    ),
    description: item.name,
  }));

  const { data: reportData, isLoading } = useDeepScanReportData(application.deepScanId || '', {
    enabled: Boolean(application.deepScanId),
  });

  const aiExposureMarkers = [{ label: 'AI Exposure Score', value: reportData?.aiSecurity?.exposureScore || 0 }];

  const securityGraphMarkers = [
    { label: 'Your score', value: securityScan.securityStats?.securityScore?.businessScore.total },
    {
      label: 'Industry Average',
      value: securityScan.securityStats?.securityScore?.industryAverage,
      type: MarkerType.BLACK,
      showLabel: true,
    },
  ];

  const insurabilityGraphMarkers = [
    { label: 'Your score', value: securityScan.securityStats?.insurabilityScore?.businessScore.total },
    {
      label: 'Industry Average',
      value: securityScan.securityStats?.insurabilityScore?.industryAverage,
      type: MarkerType.BLACK,
      showLabel: true,
    },
  ];

  const handleGoToForm = () => {
    navigate(insertIdToRoute(`${sourceRoute.ROOT}/${sourceRoute.FORM}`, applicationId));
    // window.scrollTo(0, 0);
    document.querySelector('body')?.scrollIntoView({ behavior: 'smooth' });
  };

  const handleGoToFinding = (findingId?: string) =>
    navigate(
      createSecurityRoute(
        `${sourceRoute.ROOT}/${sourceRoute.security.ROOT}/${sourceRoute.security.FINDING}`,
        applicationId,
        securityScanId,
        findingId ? findingId : '',
      ),
    );

  const actionItems: ActionItem[] = useMemo(() => {
    if (!securityScan.insurabilityScan) return [];

    return ([] as Finding[])
      .concat(
        securityScan.insurabilityScan.insurability.highSeverity ?? [],
        securityScan.insurabilityScan.insurability.mediumSeverity ?? [],
        securityScan.insurabilityScan.insurability.lowSeverity ?? [],
      )
      .filter((f) => f.values.length);
  }, [securityScan]);

  const { findingsByType } = useExternalScanFindings({ securityScan });
  const { getGrateByType } = useExternalScanGrades({ securityScan });

  const isDataVisible = useMemo(() => {
    return (
      isAdmin ||
      (securityScan?.securityOverallScore &&
        ![
          ApplicationStatus.CONFIGURATION_PENDING,
          ApplicationStatus.CONFIGURATION_DONE,
          ApplicationStatus.DATA_PENDING,
        ].includes(application.applicationStatus))
    );
  }, [isAdmin, securityScan?.securityOverallScore, application.applicationStatus]);

  const aiApps = useMemo(() => {
    return sortBy(
      (report?.applicationsRiskReport || []).filter((a) => a.isAI),
      'visits',
    ).reverse();
  }, [report]);

  const [aiPage, setAiPage] = useState(0);
  const aiColumns = useMemo((): Column<ApplicationsRiskReport, keyof ApplicationsRiskReport>[] => {
    return [
      {
        title: 'Application',
        sortKey: 'application',
        render: (row) => row.application,
      },
      {
        title: 'Total Visits',
        sortKey: 'visits',
        render: (row) => row.visits,
      },
      {
        title: 'Users',
        sortKey: 'users',
        render: (row) => row.users,
      },
    ];
  }, []);

  const aiUsers = useMemo(() => {
    return Math.max(...(deepScanTargets || []).map((t) => t.nmapScanStats?.aiScore?.uniqAiUsers || 0));
  }, [deepScanTargets]);

  const overviewCards = useMemo(() => {
    if (isScanApplication(application)) {
      if (!isDataVisible) return;

      if (
        isLoading ||
        isLoadingDeepScan ||
        !reportData ||
        isLoadingReport ||
        isTargetsLoading ||
        isLoadingBrowserPassword
      ) {
        return <CircularProgress thickness={5} className={classes.scanning} />;
      }

      const criticalVulnerabilities = (deepScanTargets || [])
        .map((t) => t.nmapScanStats?.cveCounts?.high || 0)
        .reduce((a, b) => a + b, 0);
      const riskyApps = (report?.applicationsRiskReport || []).filter((a) => a.risk == 'High');
      const weakPasswords = (browserPasswords || []).filter((a) => a.passwordStrength && a.passwordStrength <= 0.38);

      return (
        <>
          <div className={classes.multiColumn}>
            <ScoreSecurityCard
              className={classes.securityCard}
              title='Devices'
              skipGrading={deepScanTargets?.length || 0}
            />
            <ScoreSecurityCard
              className={classes.securityCard}
              title='Domains & IP Addresses'
              skipGrading={
                uniq(
                  [securityScan?.domain]
                    .concat(
                      securityScan?.subDomains || [],
                      securityScan?.ipAddresses || [],
                      securityScan?.otherDomains || [],
                    )
                    .filter((d) => (securityScan.excludedTargets || []).indexOf(d) <= -1),
                ).length
              }
            />
          </div>
          <div className={classes.multiColumn}>
            <ScoreSecurityCard
              className={classes.securityCard}
              title='Critical Vulnerabilities'
              skipGrading={(criticalVulnerabilities || 0).toLocaleString()}
              score={criticalVulnerabilities > 0 ? 50 : (reportData.networkSecurity.score || 0) * 100}
            />
            <ScoreSecurityCard
              className={classes.securityCard}
              title='Risky Applications'
              skipGrading={(riskyApps.length || 0).toLocaleString()}
              score={riskyApps.length > 0 ? 50 : (reportData.applicationSecurity.score || 0) * 100}
            />
            <ScoreSecurityCard
              className={classes.securityCard}
              title='Data Risk'
              skipGrading={`${deepScan?.piiConfig?.currency}${(
                report?.actifileRiskReport?.overallSummary.totalRisk || 0
              )?.toLocaleString()}`}
              score={(reportData.dataSecurity.score || 0) * 100}
            />
            <ScoreSecurityCard
              className={classes.securityCard}
              title='Weak Passwords'
              skipGrading={(weakPasswords.length || 0).toLocaleString()}
              score={weakPasswords.length > 0 ? 50 : (reportData.identityAccessManagement.score || 0) * 100}
            />
          </div>
          {/*<ScoreSecurityCard
            className={classes.securityCard}
            title={
              <>
                Network
                <br />
                Security
              </>
            }
            score={(reportData.networkSecurity.score || 0) * 100}
          />
          <ScoreSecurityCard
            className={classes.securityCard}
            title={
              <>
                Application
                <br />
                Security
              </>
            }
            score={(reportData.applicationSecurity.score || 0) * 100}
          />
          <ScoreSecurityCard
            className={classes.securityCard}
            title={
              <>
                Data
                <br />
                Security
              </>
            }
            score={(reportData.dataSecurity.score || 0) * 100}
          />
          <ScoreSecurityCard
            className={classes.securityCard}
            title={
              <>
                Identity and
                <br />
                Access Management
              </>
            }
            score={(reportData.identityAccessManagement.score || 0) * 100}
          />
          <ScoreSecurityCard
            className={classes.securityCard}
            title={
              <>
                Dark Web
                <br />
                Presence
              </>
            }
            score={(reportData.securityAwarenessTraining.score || 0) * 100}
          />*/}
        </>
      );
    } else {
      const typosquattingDomains =
        (findingsByType[SecurityScanType.SOCIAL_ENGINEERING] || []).find(
          (f) => f.slug === SecurityFindingSlug.TYPO_SQUATTING,
        )?.count || 0;

      const highSeverityPorts =
        (findingsByType[SecurityScanType.NETWORK_SECURITY] || []).find(
          (f) => f.slug === SecurityFindingSlug.HIGH_SEVERITY_PORT_DETECTED,
        )?.count || 0;

      const dmarcCriticalIssues =
        (findingsByType[SecurityScanType.DNS_HEALTH] || []).find(
          (f) => f.slug === SecurityFindingSlug.DMARC_CRITICAL_PROBLEMS,
        )?.count || 0;

      const httpsNotEnforced =
        (findingsByType[SecurityScanType.APPLICATION_SECURITY] || []).find(
          (f) => f.slug === SecurityFindingSlug.HTTPS_NOT_ENFORCED,
        )?.count || 0;

      return (
        <>
          <div className={classes.multiColumn}>
            <ScoreSecurityCard
              className={classes.securityCard}
              title='Domains & IP Addresses'
              skipGrading={
                uniq(
                  [securityScan?.domain]
                    .concat(
                      securityScan?.subDomains || [],
                      securityScan?.ipAddresses || [],
                      securityScan?.otherDomains || [],
                    )
                    .filter((d) => (securityScan.excludedTargets || []).indexOf(d) <= -1),
                ).length
              }
            />
            <ScoreSecurityCard
              className={classes.securityCard}
              title='External Scan Score'
              score={((securityScan?.securityScore || 0) * 100) / 5}
            />
          </div>

          <div className={classes.multiColumn}>
            <ScoreSecurityCard
              className={classes.securityCard}
              title='Typo-squatting Domains'
              skipGrading={(typosquattingDomains || 0).toLocaleString()}
              score={getScoreValueForGrade(getGrateByType(SecurityScanType.SOCIAL_ENGINEERING))}
            />
            <ScoreSecurityCard
              className={classes.securityCard}
              title='High-severity Open Ports'
              skipGrading={(highSeverityPorts || 0).toLocaleString()}
              score={getScoreValueForGrade(getGrateByType(SecurityScanType.NETWORK_SECURITY))}
            />
            <ScoreSecurityCard
              className={classes.securityCard}
              title='HTTPS Not Enforced'
              skipGrading={(httpsNotEnforced || 0).toLocaleString()}
              score={getScoreValueForGrade(getGrateByType(SecurityScanType.APPLICATION_SECURITY))}
            />
            <ScoreSecurityCard
              className={classes.securityCard}
              title='DMARC Critical Issues'
              skipGrading={(dmarcCriticalIssues || 0).toLocaleString()}
              score={getScoreValueForGrade(getGrateByType(SecurityScanType.DNS_HEALTH))}
            />
          </div>

          {/*<ScoreSecurityCard
            className={classes.securityCard}
            title={
              <>
                External Scan
                <br />
                Score
              </>
            }
            score={((securityScan?.securityScore || 0) * 100) / 5}
          />
          <ScoreSecurityCard
            className={classes.securityCard}
            title={
              <>
                Domains
                <br />
                IP Addresses
              </>
            }
            skipGrading={
              uniq(
                [securityScan?.domain]
                  .concat(
                    securityScan?.subDomains || [],
                    securityScan?.ipAddresses || [],
                    securityScan?.otherDomains || [],
                  )
                  .filter((d) => (securityScan.excludedTargets || []).indexOf(d) <= -1),
              ).length
            }
          />
          <ScoreSecurityCard
            className={classes.securityCard}
            title={
              <>
                Questionnaire
                <br />
                Score
              </>
            }
            score={securityScan?.securityStats?.securityScore?.businessScore?.total || 0}
          />
          <ScoreSecurityCard
            className={classes.securityCard}
            title={
              <>
                Overall
                <br />
                Score
              </>
            }
            score={securityScan?.securityOverallScore || 0}
          />*/}
        </>
      );
    }
  }, [
    securityScan,
    application,
    classes,
    isLoading,
    isLoadingDeepScan,
    reportData,
    isDataVisible,
    deepScanTargets,
    report,
    deepScan,
    browserPasswords,
    isLoadingReport,
    isTargetsLoading,
    isLoadingBrowserPassword,
    findingsByType,
    getGrateByType,
  ]);

  return (
    <div className={classes.root}>
      {/* SCORES */}
      <div>{overviewCards}</div>

      {/* EXECUTIVE SUMMARY */}
      {isScanApplication(application) && !isDataVisible && (
        <SecurityCard.Badge variant='info'>
          We are gathering data from your configuration, please check back later!
        </SecurityCard.Badge>
      )}
      {isScanApplication(application) && !!isDataVisible && (
        <div className={classes.column}>
          <SecurityCard.Container className={classes.securityCard}>
            <SecurityCard.Header title='Executive Summary' />
            <SecurityCard.Content gap={theme.spacing(1.5)}>
              <div className={classes.doubleColumn}>
                <div className={classes.radarSecurityCardContent} id='score-benchmark-risk-exposure-chart'>
                  <div className={classes.radarWrapper}>
                    <RiskAssessmentRadarChart reportData={reportData} isLoading={isLoading} />
                  </div>
                </div>
                <div>
                  <ExecutiveSummary reportData={reportData} isLoading={isLoading} />
                </div>
              </div>
            </SecurityCard.Content>
            {isScanApplication(application) &&
              (!application?.applicationResponse?.primary_operations ||
                !application?.applicationResponse?.annual_revenue) && (
                <SecurityCard.Badge variant='info'>
                  Please{' '}
                  <Link to='#' onClick={() => scrollToFinancialCalculator()}>
                    fill out revenue and primary operations
                  </Link>{' '}
                  to compare scores against benchmarks
                </SecurityCard.Badge>
              )}
          </SecurityCard.Container>
        </div>
      )}

      {/* EXTERNAL SCAN */}
      {isExternalScan && securityScan?.domain !== 'nodomain.local' ? (
        <div className={classes.column}>
          <SecurityCard.Container className={classes.securityCard}>
            <SecurityCard.Header
              title='External Surface Exposure'
              actions={
                <>
                  {securityScanId === 'latest' && (
                    <RescanButton
                      applicationId={applicationId}
                      securityScanId={securityScanId}
                      isScanning={false}
                      className={classes.noBorder}
                    />
                  )}
                  <IconButton onClick={handleOpenExternalScan}>
                    <LaunchRoundedIcon />
                  </IconButton>
                </>
              }
            />
            <SecurityCard.Content gap={theme.spacing(1.5)} handleGoToForm={handleGoToForm}>
              <div className={classes.doubleColumn}>
                <div className={classes.radarSecurityCardContent} id='score-benchmark-external-surface-exposure-chart'>
                  <div className={classes.radarWrapper}>
                    <ExternalScanRadarChart securityScan={securityScan} />
                  </div>
                </div>
                <div>
                  <SecurityExternalScan
                    sourceRoute={sourceRoute}
                    tile
                    securityScan={securityScan}
                    applicationId={applicationId}
                    securityScanId={securityScanId}
                  />
                  {securityScan.status != SecurityScanStatus.COMPLETED && (
                    <span className={classes.lastScanned}>
                      <CircularProgress size={15} thickness={5} className={classes.scanning} />
                      Scanning {<DomainInfo securityScan={securityScan} />}...
                    </span>
                  )}
                  {securityScan.status == SecurityScanStatus.COMPLETED && securityScan.updatedAt && (
                    <span className={classes.lastScanned}>
                      {securityScanId == 'latest' && (
                        <>
                          <span>Last scanned</span>
                          <DomainInfo securityScan={securityScan} /> on:{' '}
                        </>
                      )}
                      {securityScanId != 'latest' && 'Historical report as on: '}
                      {moment(securityScan.updatedAt).format('Do MMMM YYYY hh:mm:ss A')}
                      {securityScanId != 'latest' && (
                        <Link
                          replace
                          className={classes.viewLatest}
                          to={createSecurityRoute(sourceRoute.security.OVERVIEW, applicationId, 'latest')}
                        >
                          View Latest
                        </Link>
                      )}
                    </span>
                  )}
                </div>
              </div>
            </SecurityCard.Content>
            {!isScanApplication(application) &&
              (!application?.applicationResponse?.primary_operations ||
                !application?.applicationResponse?.annual_revenue) && (
                <SecurityCard.Badge variant='info'>
                  Please{' '}
                  <Link to='#' onClick={() => scrollToFinancialCalculator()}>
                    fill out revenue and primary operations
                  </Link>{' '}
                  to compare scores against benchmarks
                </SecurityCard.Badge>
              )}
          </SecurityCard.Container>
        </div>
      ) : null}

      {/* AI EXPOSURE */}
      {!isExternalScan && (
        <div className={classes.column}>
          <SecurityCard.Container className={classes.securityCard}>
            <SecurityCard.Header
              title={
                <span>
                  AI Exposure Score &nbsp; &nbsp;
                  <span
                    className={classes.securityScoreTitle}
                    style={{
                      color: reportData?.aiSecurity?.exposureScore
                        ? mapGradeToColor(parseScoreToGrade(100 - reportData?.aiSecurity?.exposureScore || 0))
                        : COLORS.GREY_2,
                    }}
                  >
                    {mapSecurityScoreLabel(reportData?.aiSecurity?.exposureScore || 0, true)}
                  </span>
                  <Modal openButton={<InfoRoundedIcon className={classes.infoIcon} />} title='AI Exposure Score'>
                    <p className={classes.greyText}>
                      This score shows an organization’s risk exposure to AI-driven threats and vulnerabilities.
                    </p>
                  </Modal>
                </span>
              }
            />
            <SecurityCard.Content gap={theme.spacing(2.5)}>
              <Placeholder
                isActive={isLoading || isLoadingReport || isTargetsLoading}
                className={classes.noBreachesWrapper}
              >
                <div className={classes.aiColumn} style={{ minHeight: 220, gap: 64 }}>
                  <div style={{ position: 'relative', top: '-16px' }}>
                    <div className={classes.gaugeWrapper}>
                      <GaugeChart value={reportData?.aiSecurity?.exposureScore || 0} markers={aiExposureMarkers} />
                    </div>
                  </div>
                  <div>
                    <ScoreSecurityCard
                      className={classes.securityCard}
                      title='Total AI Applications'
                      skipGrading={(aiApps?.length || 0).toLocaleString()}
                      score={100 - (reportData?.aiSecurity?.exposureScore || 0)}
                    />
                    <br />
                    <ScoreSecurityCard
                      className={classes.securityCard}
                      title='Total AI Users'
                      skipGrading={(aiUsers || 0).toLocaleString()}
                      score={100 - (reportData?.aiSecurity?.exposureScore || 0)}
                    />
                  </div>
                  <div>
                    <Table<ApplicationsRiskReport, keyof ApplicationsRiskReport>
                      columns={aiColumns}
                      rowContentCentered
                      onChange={(newPage) => {
                        if (newPage?.page !== undefined) {
                          setAiPage(newPage.page);
                        }
                      }}
                      className={classes.table}
                      rowKey={(row) => row.application}
                      data={aiApps.slice(aiPage * AI_PER_PAGE, (aiPage + 1) * AI_PER_PAGE)}
                      loading={isLoadingReport}
                      pagination={
                        isLoadingReport ? undefined : { page: aiPage, perPage: AI_PER_PAGE, total: aiApps?.length || 0 }
                      }
                    />
                  </div>
                </div>
              </Placeholder>
            </SecurityCard.Content>
          </SecurityCard.Container>
        </div>
      )}

      {/* DARK WEB PRESENCE */}
      <div className={classes.column}>
        <SecurityCard.Container className={classes.securityCard}>
          <SecurityCard.Header title='Dark Web Presence' />
          <SecurityCard.Content gap={8} className={classes.darkWebWrapper}>
            <DarkWebPresenceChart
              showShowMore
              securityScan={securityScan}
              findingsUrl={createSecurityRoute(
                `${sourceRoute.ROOT}/${sourceRoute.security.ROOT}/${sourceRoute.security.FINDINGS}`,
                applicationId,
                securityScan.id,
                '',
              )}
              applicationId={applicationId}
            />
          </SecurityCard.Content>
        </SecurityCard.Container>
      </div>

      {/* FINANCIAL EXPOSURE */}
      <div className={classes.column} id='financial-exposure'>
        <SecurityCard.Container className={classes.securityCard}>
          <SecurityCard.Header title='Financial Exposure' />
          <SecurityCard.Content gap={theme.spacing(2.5)}>
            <div className={classes.doubleColumn} style={{ minHeight: 350, gap: 64 }}>
              <div className='relative'>
                <div>
                  <p className={classes.greyText}>
                    <b>Ransomware Cost</b>
                  </p>

                  <Money
                    value={
                      securityScan.securityStats.ransomwareCost
                        ? Math.trunc(securityScan.securityStats.ransomwareCost)
                        : 0
                    }
                    typography={TYPOGRAPHY.TITLE_3}
                    isLoading={isSavingFinancialCalculator}
                    color={COLORS.RED_1}
                    piiCountry={defaultPIICountry}
                    piiCurrency={defaultPIICurrency}
                  />

                  <p className={classes.greyText}>
                    <b>70%</b> of all cyber attacks are ransomware.{' '}
                    <a
                      href='https://telivy.notion.site/Ransomware-05292a9956234d1d82433caf0071d36b'
                      target='_blank'
                      rel='noreferrer'
                    >
                      Learn more
                    </a>
                  </p>
                </div>
                <br />

                <div>
                  <p className={classes.greyText}>
                    <b>Interruption Cost</b>
                  </p>

                  <Money
                    value={
                      securityScan.securityStats.businessInterruptionCost
                        ? Math.trunc(securityScan.securityStats.businessInterruptionCost)
                        : 0
                    }
                    typography={TYPOGRAPHY.TITLE_3}
                    isLoading={isSavingFinancialCalculator}
                    color={COLORS.RED_1}
                    piiCountry={defaultPIICountry}
                    piiCurrency={defaultPIICurrency}
                  />

                  <p className={classes.greyText}>
                    Lost business represented <b>38%</b> of the overall loss.{' '}
                    <a
                      href='https://telivy.notion.site/Business-Interruption-bb93e193f2794867847edbfd2ffc8415'
                      target='_blank'
                      rel='noreferrer'
                    >
                      Learn more
                    </a>
                  </p>
                </div>
                <br />

                <div>
                  <p className={classes.greyText}>
                    <b>Data Breach Cost</b>
                  </p>
                  <Money
                    value={dataBreachCost}
                    typography={TYPOGRAPHY.TITLE_3}
                    isLoading={isSavingFinancialCalculator}
                    color={COLORS.RED_1}
                    piiCountry={defaultPIICountry}
                    piiCurrency={defaultPIICurrency}
                  />

                  <div>
                    <p className={classes.greyText}>
                      <b>80%</b> of the breaches included records containing customer PII, at an average cost of{' '}
                      <b>
                        {defaultPIICurrency}
                        {150 * PiiCurrencyConversion[defaultPIICountry]}
                      </b>{' '}
                      per record.{' '}
                      <a
                        href='https://telivy.notion.site/Data-Breach-891d5dec117546f7b034a313cd494d04'
                        target='_blank'
                        rel='noreferrer'
                      >
                        Learn more
                      </a>
                    </p>

                    <br />

                    <Modal
                      openButton={<LinkButton className={classes.modalButton}>Show Cost Breakup</LinkButton>}
                      title='Data Breach Cost Breakup'
                      padding={4}
                      autoWidth
                    >
                      <div>
                        <VerticalStepper
                          mainValue={
                            <Money
                              value={dataBreachCost}
                              typography={TYPOGRAPHY.TITLE_3}
                              className={classes.redText}
                              piiCountry={defaultPIICountry}
                              piiCurrency={defaultPIICurrency}
                            />
                          }
                          items={dataBreachItems}
                        />
                      </div>
                    </Modal>
                  </div>
                </div>

                {user?.agency && deepScan && isAgentAnAnyAdmin(user) && (
                  <div className='absolute -top-2 right-0'>
                    <ConfigurePiiCostsModal
                      entityId={deepScan.id}
                      type='deep-scan'
                      defaultValues={deepScan.piiConfig}
                      label='Confugure PII Settings'
                    />
                  </div>
                )}
              </div>
              <div>
                <FinancialRiskCalculator
                  application={application}
                  setIsSavingFinancialCalculator={setIsSavingFinancialCalculator}
                />
              </div>
            </div>
          </SecurityCard.Content>
        </SecurityCard.Container>
      </div>

      <div className={classes.doubleColumn} style={{ display: isScanApplication(application) ? 'none' : 'block' }}>
        {/* INSURABILITY & SECURITY SCORE */}
        <div
          className={cx(
            classes.gaugeColumn,
            (!securityScan.securityStats?.insurabilityScore || !securityScan.securityStats?.insurabilityScore) &&
              classes.gaugeColumnOneItem,
          )}
        >
          {/* INSURABILITY SCORE */}
          {securityScan.securityStats?.insurabilityScore && (
            <div className={classes.column}>
              <SecurityCard.Container className={classes.securityCard}>
                <SecurityCard.Header
                  title={
                    <span>
                      Insurability Score
                      {!isNotSubmitted && (
                        <>
                          :{' '}
                          <span className={classes.securityScoreTitle}>
                            {mapSecurityScoreLabel(securityScan.securityStats?.insurabilityScore?.businessScore.total)}
                          </span>
                        </>
                      )}
                      <Modal openButton={<InfoRoundedIcon className={classes.infoIcon} />} title='Insurability Score'>
                        <p className={classes.greyText}>This Score is based on the answers in your questionaire.</p>
                      </Modal>
                    </span>
                  }
                />
                <SecurityCard.Content
                  gap={theme.spacing()}
                  isNotSubmitted={isNotSubmitted}
                  handleGoToForm={handleGoToForm}
                  className={classes.gaugeSecurityCardContent}
                >
                  <div className={classes.gaugeWrapper}>
                    <GaugeChart
                      value={securityScan.securityStats?.insurabilityScore.businessScore.total}
                      markers={insurabilityGraphMarkers}
                    />
                  </div>

                  <div className={classes.gaugeSecurityCardContentInnerContent}>
                    {securityScan.securityStats?.insurabilityScore?.businessScore && (
                      <>
                        <Badge
                          variant={getInsurabilityScoreBadgeVariant(
                            securityScan.securityStats?.insurabilityScore?.businessScore?.total || 0,
                          )}
                          bordered
                          borderWidth={2}
                          style={{ display: 'block', padding: '8px 16px' }}
                          typography={TYPOGRAPHY.REGULAR_MEDIUM}
                        >
                          <span style={{ color: 'black' }}>Insurability score:</span>{' '}
                          <b>{Math.round(securityScan.securityStats?.insurabilityScore?.businessScore?.total || 0)}%</b>
                        </Badge>

                        {securityScan?.securityStats?.insurabilityScore?.businessScore?.insurabilityScoreComponents ? (
                          <Modal
                            removePaddingTop
                            title='Insurability Score'
                            openButton={
                              <span className={classes.modalButton}>
                                <InfoRoundedIcon className={classes.modalButtonIcon} />
                                Learn more
                              </span>
                            }
                            // actions={(handleClose) => (
                            //   <>
                            //     <Button onClick={handleClose}>Close</Button>
                            //     <Button
                            //       variant='contained'
                            //       color='primary'
                            //       onClick={() => {
                            //         navigate(
                            //           createSecurityRoute(sourceRoute.security.FINDINGS, applicationId, securityScanId),
                            //         );
                            //       }}
                            //     >
                            //       View Action Items
                            //     </Button>
                            //   </>
                            // )}
                          >
                            <InsurabilityScoreComponentsTable
                              components={
                                securityScan?.securityStats?.insurabilityScore?.businessScore
                                  ?.insurabilityScoreComponents
                              }
                            />
                          </Modal>
                        ) : null}
                      </>
                    )}
                    {securityScan?.securityStats?.insurabilityScore?.businessScore?.allMarketsNumber !== undefined &&
                    securityScan?.securityStats?.insurabilityScore?.businessScore?.eligibleMarketsNumber !==
                      undefined &&
                    securityScan?.securityStats?.insurabilityScore?.businessScore?.quotableMarketsNumber !==
                      undefined ? (
                      <div className={classes.insurabilityMarketsWrapper}>
                        <InsurabilityScoreCircularProgress
                          total={securityScan?.securityStats?.insurabilityScore?.businessScore?.allMarketsNumber}
                          items={securityScan?.securityStats?.insurabilityScore?.businessScore?.eligibleMarketsNumber}
                          title='Addressable Market:'
                          label='carriers'
                          learnMoreSection={
                            <Modal
                              openButton={
                                <span className={classes.modalButton}>
                                  <InfoRoundedIcon className={classes.modalButtonIcon} />
                                  Learn more
                                </span>
                              }
                              title='Addressable Market'
                            >
                              <p>
                                We track the commercial appetite of 64 insurance carriers and apply primary filters to
                                determine those interested in quoting a given company. These filters include: prior
                                claims and breaches, industry classification, and size (revenue and/or number or value
                                of records).
                              </p>
                              <p style={{ fontSize: 14, fontWeight: 700 }}>Why is this Relevant: </p>
                              <p style={{ marginTop: 0 }}>
                                The insurance market employs the highest number of quantitative analysts, and they
                                review the underwriting performance and threat vectors of tens of millions of applicants
                                and insureds every year. This insurability assessment helps rank which controls are most
                                pertinent based on their research.
                              </p>
                            </Modal>
                          }
                        />
                        <InsurabilityScoreCircularProgress
                          total={securityScan?.securityStats?.insurabilityScore?.businessScore?.eligibleMarketsNumber}
                          items={securityScan?.securityStats?.insurabilityScore?.businessScore?.quotableMarketsNumber}
                          title='Quotable Market:'
                          label='carriers'
                          learnMoreSection={
                            <Modal
                              openButton={
                                <span className={classes.modalButton}>
                                  <InfoRoundedIcon className={classes.modalButtonIcon} />
                                  Learn more
                                </span>
                              }
                              title='Quotable Market'
                            >
                              <p>
                                After the primary filter is applied and the addressable market is determined, our
                                secondary and tertiary filters include internal controls, governance, and external
                                security posture. We can then estimate the number of carriers that would quote versus
                                the addressable market.
                              </p>
                              <p style={{ fontSize: 14, fontWeight: 700 }}>Why is this Relevant: </p>
                              <p style={{ marginTop: 0 }}>
                                The insurance market employs the highest number of quantitative analysts, and they
                                review the underwriting performance and threat vectors of tens of millions of applicants
                                and insureds every year. This insurability assessment helps rank which controls are most
                                pertinent based on their research.
                              </p>
                            </Modal>
                          }
                        />
                      </div>
                    ) : null}
                    {/* <Button className={classes.centerButton} onClick={onViewQuestionnaireClick}>
                    View Questionnaire
                  </Button> */}
                  </div>
                </SecurityCard.Content>
              </SecurityCard.Container>
            </div>
          )}

          {/* SECURITY SCORE */}
          {securityScan.securityStats?.securityScore && (
            <div className={classes.column}>
              <SecurityCard.Container className={classes.securityCard}>
                <SecurityCard.Header
                  title={
                    <span>
                      Security Score
                      {!isNotSubmitted && (
                        <>
                          :{' '}
                          <span className={classes.securityScoreTitle}>
                            {mapSecurityScoreLabel(securityScan.securityStats?.securityScore?.businessScore.total)}
                          </span>
                        </>
                      )}
                      <Modal openButton={<InfoRoundedIcon className={classes.infoIcon} />} title='Security Score'>
                        {/* <p className={cx(classes.greyText, classes.centerText)}> */}
                        <p className={classes.greyText}>This Score is based on the answers in your questionaire.</p>
                      </Modal>
                    </span>
                  }
                />
                <SecurityCard.Content
                  gap={theme.spacing(1.5)}
                  isNotSubmitted={isNotSubmitted}
                  handleGoToForm={handleGoToForm}
                  className={classes.gaugeSecurityCardContent}
                >
                  <div className={classes.gaugeWrapper}>
                    <GaugeChart
                      value={securityScan.securityStats?.securityScore.businessScore.total}
                      markers={securityGraphMarkers}
                    />
                  </div>
                  <div className={classes.gaugeSecurityCardContentInnerContent}>
                    <Button className={classes.centerButton} onClick={onViewQuestionnaireClick}>
                      View Questionnaire
                    </Button>
                  </div>
                </SecurityCard.Content>
              </SecurityCard.Container>
            </div>
          )}
        </div>

        {/* ACTION ITEMS */}
        <div className={classes.column}>
          <SecurityCard.Container className={classes.securityCard}>
            <SecurityCard.Header
              title={
                <span>
                  Action Items to Improve Your Score
                  <a
                    href='https://telivy.notion.site/Improving-your-Security-Posture-03434f720a3746e4a1a240dd95029fc3'
                    target='_blank'
                    rel='noreferrer'
                  >
                    <InfoRoundedIcon className={classes.infoIcon} />
                  </a>
                </span>
              }
            />
            <SecurityCard.Content
              gap={8}
              isNotSubmitted={isNotSubmitted}
              handleGoToForm={handleGoToForm}
              padding='0 24px 0 24px'
            >
              <ActionItems items={actionItems} handleGoToFinding={handleGoToFinding} />
            </SecurityCard.Content>
          </SecurityCard.Container>
        </div>
      </div>

      {/* SERVICES DETECTED */}
      {/*isAdmin && (
        <div className={classes.column}>
          <SecurityCard.Container className={classes.securityCard}>
            <SecurityCard.Header
              title='Services Detected'
              actions={
                <LinkButton
                  className={classes.blueLinkButton}
                  onClick={() =>
                    navigate(
                      createSecurityRoute(sourceRoute.security.TECHSTACK, applicationId, securityScan.id, ''),
                    )
                  }
                >
                  View all
                </LinkButton>
              }
            />
            <SecurityCard.Content handleGoToForm={handleGoToForm} maxHeight={theme.spacing(44)}>
              <TechstackTools tile application={application} />
            </SecurityCard.Content>
          </SecurityCard.Container>
        </div>
      )*/}
    </div>
  );
};

const getInsurabilityScoreBadgeVariant = (score: number): BadgeVariant => {
  if (score > 50) {
    return 'blue';
  }
  if (score > 25) {
    return 'yellow';
  }
  if (score > 0) {
    return 'red';
  }
  return 'darkRed';
};
